import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  students: {
    data: [],
    totalPage: 0,
    totalData: 0,
    pageNumber: 0,
    hasNext: 0,
  },
  activeStudents: {
    data: [],
    totalPage: 0,
    totalData: 0,
    pageNumber: 0,
    hasNext: 0,
  },
  studentById: {},
};

const StudentReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_ALL_STUDENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ALL_STUDENT_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        students: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ALL_STUDENT_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to fetch Students.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_STUDENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ACTIVE_STUDENT_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        activeStudents: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACTIVE_STUDENT_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to fetch Students.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_STUDENT_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_STUDENT_BY_ID_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        studentById: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_STUDENT_BY_ID_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to fetch Student data.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_STUDENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_STUDENT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Student added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_STUDENT_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to add Student.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_STUDENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_STUDENT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Student updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_STUDENT_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to update Student.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_STUDENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_STUDENT_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Student deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_STUDENT_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete Student.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_STUDENT_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_STUDENT_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Student status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_STUDENT_STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change Student status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default StudentReducer;
