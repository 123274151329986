import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import ClientLayout from "../ClientManagement/ClientLayout";
const { Option } = Select;

const AssignConsultancy = ({
  record,
  isAssignConsultancyModalVisible,
  setIsAssignConsultancyModalVisible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const AssignConsultancySubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
    };
    dispatch({ type: "AssignConsultancy_REQUEST", payload: formData });
    // form.resetFields();
    setIsAssignConsultancyModalVisible(false);
  };
  return (
    <Drawer
      title="Assign Consultancy"
      open={isAssignConsultancyModalVisible}
      onClose={() => {
        setIsAssignConsultancyModalVisible(false);
      }}
      footer={null}
      width={"90vw"}
    >
      <ClientLayout isAssignConsultancy={true} enquiryRecord={record} />
    </Drawer>
  );
};

export default AssignConsultancy;
