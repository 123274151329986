import React from "react";
import PaymentSuccess from "./PaymentSuccess";
import greenTick from "./../Assets/greenTick.png";

const SubscriptionSuccess = () => {
  return (
    <PaymentSuccess
      image={greenTick}
      messageText1="Congratulations !!!"
      messageText2="Your Subscription plan is successfully granted."
    />
  );
};

export default SubscriptionSuccess;
