import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../components/Siderdemo";
import AddEducationVisaSubclass from "./AddEducationVisaSubclass";
import { allActions } from "../../Redux/myActions";

const EducationVisaTypes = () => {
  const store = useSelector((state) => {
    return {
      educationVisasState: state.VisaClassReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
      visaSubclassState: state?.VisaSubclassReducer,
    };
  });
  const [isAddEducationVisaModalVisible, setIsAddEducationVisaModalVisible] =
    useState(false);
  const [
    isUpdateEducationVisaModalVisible,
    setIsUpdateEducationVisaModalVisible,
  ] = useState(false);
  const [isVisaDetailModelVisible, setIsVisaDetailModelVisible] =
    useState(false);
  const [record, setRecord] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState("");

  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };

  const actions = useDispatch();

  useEffect(() => {
    fetchAdminDropdown();
    fetchSubclassByCategory();
  }, []);

  const fetchAdminDropdown = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/institute/get-all-dropdown`,
          attempt: "FETCH_ADMIN_DROPDOWN",
          success: "FETCH_ADMIN_DROPDOWN_SUCCESS",
          failure: "FETCH_ADMIN_DROPDOWN_FAILURE",

          saveBearerToken: false,
        }
      )
    );
  };

  const fetchSubclassByCategory = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/visa-sub-class/get-all-according-to-category/EDUCATION`,
          attempt: "FETCH_VISA_SUBCLASS_REQUEST",
          success: "FETCH_VISA_SUBCLASS_REQUEST_SUCCESS",
          failure: "FETCH_VISA_SUBCLASS_REQUEST_FAILURE",
          saveBearerToken: false,
          successInternalState: () => {},
        }
      )
    );
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "Country",
      dataIndex: "country",
      align: "center",
    },
    {
      title: "Sub Class",
      dataIndex: "subClass",
      align: "center",
    },

    {
      title: "Actions",
      align: "center",
      render: (dataObj) => (
        <Space size="middle">
          <Tooltip title="Update educationVisas">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateEducationVisaModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete educationVisas">
            <Popconfirm
              placement="topRight"
              title="Are you sure to delete this Education VisaSubclass?"
              onConfirm={() => {
                actions(
                  allActions(
                    {},
                    {
                      method: "delete",
                      endPoint: `/visa-sub-class/delete/${dataObj?.id}`,
                      attempt: "DELETE_EDUCATION_VISAS_REQUEST",
                      success: "DELETE_EDUCATION_VISAS_REQUEST_SUCCESS",
                      failure: "DELETE_EDUCATION_VISAS_REQUEST_SUCCESS",
                      saveBearerToken: false,
                      successInternalState: () => {
                        fetchSubclassByCategory();
                      },
                    }
                  )
                );
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined style={{ color: "red" }} />
                </div>
              </a>{" "}
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          <button
            className="button"
            onClick={() => {
              setIsAddEducationVisaModalVisible(true);
            }}
          >
            <span>Add Education Visa</span>
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          <Table
            bordered
            columns={columns}
            dataSource={store?.visaSubclassState?.visaSubclass?.data}
            pagination={false}
          />
          <Pagination
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
              alignSelf: "flex-end",
            }}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            defaultCurrent={1}
            current={page}
            defaultPageSize={pageSize}
            onChange={onShowSizeChange}
            // total={store?.educationVisasState?.educationVisas?.totalPage * 10}
          />
        </div>
      </div>
      {isAddEducationVisaModalVisible && (
        <AddEducationVisaSubclass
          fetchSubclassByCategory={fetchSubclassByCategory}
          modalType="Add"
          record=""
          isAddEducationVisaModalVisible={isAddEducationVisaModalVisible}
          setIsAddEducationVisaModalVisible={setIsAddEducationVisaModalVisible}
        />
      )}

      {isUpdateEducationVisaModalVisible && (
        <AddEducationVisaSubclass
          fetchSubclassByCategory={fetchSubclassByCategory}
          modalType="Edit"
          record={record}
          isAddEducationVisaModalVisible={isUpdateEducationVisaModalVisible}
          setIsAddEducationVisaModalVisible={
            setIsUpdateEducationVisaModalVisible
          }
        />
      )}
    </SiderDemo>
  );
};

export default EducationVisaTypes;
