import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../components/Siderdemo";

import Cookies from "js-cookie";
import axios from "axios";
import AddEditMigarionVisaSubclass from "./AddEditMigrationVisaSubclass";
import { allActions } from "../../Redux/myActions";

const MigrationVisas = () => {
  const store = useSelector((state) => {
    return {
      educationVisasState: state.VisaClassReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
      visaSubclassState: state?.VisaSubclassReducer,
    };
  });

  const [isAddMigrationVisasModalVisible, setIsAddMigrationVisasModalVisible] =
    useState(false);
  const [
    isUpdateMigrationVisasModalVisible,
    setIsUpdateMigrationVisasModalVisible,
  ] = useState(false);

  const [record, setRecord] = useState({});

  const actions = useDispatch();
  const [countryList, setCountryList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [country, setCountry] = useState("");
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };

  useEffect(() => {
    fetchAdminDropdown();
    fetchSubclassByCategory();
  }, []);

  const fetchAdminDropdown = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/institute/get-all-dropdown`,
          attempt: "FETCH_ADMIN_DROPDOWN",
          success: "FETCH_ADMIN_DROPDOWN_SUCCESS",
          failure: "FETCH_ADMIN_DROPDOWN_FAILURE",

          saveBearerToken: false,
        }
      )
    );
  };

  const fetchSubclassByCategory = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/visa-sub-class/get-all-according-to-category/MIGRATION`,
          attempt: "FETCH_MIGRATION_VISA_SUBCLASS_REQUEST",
          success: "FETCH_MIGRATION_VISA_SUBCLASS_REQUEST_SUCCESS",
          failure: "FETCH_MIGRATION_VISA_SUBCLASS_REQUEST_FAILURE",
          saveBearerToken: false,
          successInternalState: () => {},
        }
      )
    );
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "Country",
      dataIndex: "country",
      align: "center",
    },
    {
      title: "Sub Class",
      dataIndex: "subClass",
      align: "center",
    },

    {
      title: "Actions",
      align: "center",
      render: (dataObj) => (
        <Space size="middle">
          <Tooltip title="Update migration">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateMigrationVisasModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          {/* <Tooltip title="Delete migration"> */}
          <Popconfirm
            placement="topRight"
            title="Are you sure to delete this Migration VisaSubclass?"
            onConfirm={() => {
              // dispatch({
              //   type: "DELETE_MIGRATION_VISAS_REQUEST",
              //   payload: { id: dataObj.id },
              // });

              actions(
                allActions(
                  {},
                  {
                    method: "delete",
                    endPoint: `/visa-sub-class/delete/${dataObj?.id}`,
                    attempt: "DELETE_EDUCATION_VISAS_REQUEST",
                    success: "DELETE_EDUCATION_VISAS_REQUEST_SUCCESS",
                    failure: "DELETE_EDUCATION_VISAS_REQUEST_SUCCESS",
                    saveBearerToken: false,
                    successInternalState: () => {
                      fetchSubclassByCategory();
                    },
                  }
                )
              );
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <a>
              <div className="bordered">
                <DeleteOutlined style={{ color: "red" }} />
              </div>
            </a>{" "}
          </Popconfirm>
          {/* </Tooltip> */}
        </Space>
      ),
    },
  ];

  const fetchCountry = () => {
    const tempToken = Cookies.get("accessToken");
    axios
      .get(`v1/api/country/getAllCountry`, {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      })
      .then((res) => {
        setCountryList(res.data.data);
      })
      .catch((err) => {
        alert(err);
      });
  };
  const data = [
    {
      id: 1,
      country: "Nepal",
      subClass: "500 visa",
    },
  ];

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          <button
            className="button"
            onClick={() => {
              setIsAddMigrationVisasModalVisible(true);
            }}
          >
            <span>Add Migration Visa</span>
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          <Table
            bordered
            columns={columns}
            // dataSource={data}
            dataSource={store?.visaSubclassState?.migrationVisaSubclass?.data}
            pagination={false}
          />
          <Pagination
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
              alignSelf: "flex-end",
            }}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            defaultCurrent={1}
            current={page}
            defaultPageSize={pageSize}
            onChange={onShowSizeChange}
            // total={store?.migrationState?.migrationVisas?.totalPage * 10}
          />
        </div>
      </div>
      {isAddMigrationVisasModalVisible && (
        <AddEditMigarionVisaSubclass
          fetchSubclassByCategory={fetchSubclassByCategory}
          modalType="Add"
          record=""
          isMigrationVisasModalVisible={isAddMigrationVisasModalVisible}
          setIsMigrationVisasModalVisible={setIsAddMigrationVisasModalVisible}
        />
      )}
      {isUpdateMigrationVisasModalVisible && (
        <AddEditMigarionVisaSubclass
          fetchSubclassByCategory={fetchSubclassByCategory}
          modalType="Edit"
          record={record}
          isMigrationVisasModalVisible={isUpdateMigrationVisasModalVisible}
          setIsMigrationVisasModalVisible={
            setIsUpdateMigrationVisasModalVisible
          }
        />
      )}
    </SiderDemo>
  );
};

export default MigrationVisas;
