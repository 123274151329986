import React, { useEffect } from "react";
import SiderDemo from "../../components/Siderdemo";
import { getCookie } from "../../Helpers/FrontendHelper";
import DashboardHeader from "./DashboardHeader";
import { BiCube } from "react-icons/bi";
import { BsBuilding, BsTags } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { allActions } from "../../Redux/myActions";
import { HiOutlineBuildingLibrary, HiOutlineTicket } from "react-icons/hi2";
import { AiOutlineBook } from "react-icons/ai";
import { MdSubscriptions } from "react-icons/md";

const Dashboard = () => {
  const store = useSelector((state) => {
    return {
      dashboardState: state.DashboardReducer,
    };
  });
  const actions = useDispatch();
  const fetchAllDashboardData = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/dashboard/get-all-count`,
          attempt: "FETCH_DASHBOARD_REQUEST",
          success: "FETCH_DASHBOARD_REQUEST_SUCCESS",
          failure: "FETCH_DASHBOARD_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };

  useEffect(() => {
    fetchAllDashboardData();
  }, []);
  return (
    <SiderDemo dashboard={true}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "0.3rem",
        }}
      >
        <DashboardHeader
          title={"ACTIVE CONSULTANCY"}
          end={store?.dashboardState?.dashboardData?.allActiveClient}
          dashboardHeaderIcon={<BsBuilding className="dashboardIconStyle" />}
        />
        <DashboardHeader
          title={"ALL CONSULTANCY"}
          end={store?.dashboardState?.dashboardData?.allClient}
          dashboardHeaderIcon={<BsBuilding className="dashboardIconStyle" />}
        />
        <DashboardHeader
          title={"TRIAL CONSULTANCY"}
          end={store?.dashboardState?.dashboardData?.trailSubscriptionClient}
          dashboardHeaderIcon={<BsBuilding className="dashboardIconStyle" />}
        />
        <DashboardHeader
          title={"EXPIRED CONSULTANCY"}
          end={store?.dashboardState?.dashboardData?.expiredSubscriptionClient}
          dashboardHeaderIcon={<BsBuilding className="dashboardIconStyle" />}
        />
        <DashboardHeader
          title={"ACTIVE INSTITUTES"}
          end={store?.dashboardState?.dashboardData?.totalActiveAdminInstitute}
          dashboardHeaderIcon={
            <HiOutlineBuildingLibrary className="dashboardIconStyle" />
          }
        />
        <DashboardHeader
          title={"ALL INSTITUTES"}
          end={store?.dashboardState?.dashboardData?.totalAdminInstitute}
          dashboardHeaderIcon={
            <HiOutlineBuildingLibrary className="dashboardIconStyle" />
          }
        />
        <DashboardHeader
          title={"ACTIVE COURSES"}
          end={store?.dashboardState?.dashboardData?.totalActiveAdminCourses}
          dashboardHeaderIcon={<AiOutlineBook className="dashboardIconStyle" />}
        />
        <DashboardHeader
          title={"ALL COURSES"}
          end={store?.dashboardState?.dashboardData?.totalAdminCourses}
          dashboardHeaderIcon={<AiOutlineBook className="dashboardIconStyle" />}
        />
        <DashboardHeader
          title={"OPEN SUPPORT TICKETS"}
          end={store?.dashboardState?.dashboardData?.openSupportCount}
          dashboardHeaderIcon={
            <HiOutlineTicket className="dashboardIconStyle" />
          }
        />
        <DashboardHeader
          title={"PENDING SUPPORT TICKETS"}
          end={store?.dashboardState?.dashboardData?.pendingSupportCount}
          dashboardHeaderIcon={
            <HiOutlineTicket className="dashboardIconStyle" />
          }
        />
        <DashboardHeader
          title={"CLOSED SUPPORT TICKETS"}
          end={store?.dashboardState?.dashboardData?.closedSupportCount}
          dashboardHeaderIcon={
            <HiOutlineTicket className="dashboardIconStyle" />
          }
        />

        <DashboardHeader
          title={"ACTIVE SUBSCRIPTION PACKAGES"}
          end={
            store?.dashboardState?.dashboardData
              ?.totalActiveSubscriptionPackages
          }
          dashboardHeaderIcon={
            <MdSubscriptions className="dashboardIconStyle" />
          }
        />
      </div>
    </SiderDemo>
  );
};

export default Dashboard;
