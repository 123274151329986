import { Button, Divider } from "antd";
import React from "react";
import { twoTagResponses } from "../../../../Helpers/HelperFunction";

const StudentDocuments = ({ record }) => {
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  return (
    <div>
      {record?.length > 0 ? (
        record?.map((dataObj) => {
          return (
            <div
              key={dataObj.id}
              style={{
                backgroundColor: "#eeeeee55",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                marginBottom: "0.5rem",
              }}
            >
              <div>
                {dataObj?.documentType &&
                  detailFun(
                    "Document Type",
                    `${dataObj?.documentType ? dataObj?.documentType : ""}`
                  )}
                {dataObj?.fullImagePath &&
                  detailFun(
                    "Image",
                    <a href={dataObj?.fullImagePath}>Download</a>
                  )}
                {dataObj?.documentType === "Passport" && (
                  <>
                    {dataObj?.passportNumber &&
                      detailFun(
                        "Passport Number",
                        `${
                          dataObj?.passportNumber ? dataObj?.passportNumber : ""
                        }`
                      )}
                    {dataObj?.issuedCountry &&
                      detailFun(
                        "Issued Country",
                        `${
                          dataObj?.issuedCountry ? dataObj?.issuedCountry : ""
                        }`
                      )}
                    {dataObj?.issuedDatePassport &&
                      detailFun(
                        "Issued Passport Date",
                        `${
                          dataObj?.issuedDatePassport
                            ? dataObj?.issuedDatePassport
                            : ""
                        }`
                      )}
                    {dataObj?.isPassportUploaded &&
                      detailFun(
                        "Is Passport Uploaded?",
                        twoTagResponses(
                          dataObj?.isPassportUploaded,
                          true,
                          false
                        )
                      )}
                  </>
                )}
                {dataObj?.expiryDate &&
                  detailFun(
                    "Expiry Date",
                    `${dataObj?.expiryDate ? dataObj?.expiryDate : ""}`
                  )}

                {dataObj?.image &&
                  detailFun("Image", <a href={dataObj?.image}>Download</a>)}
                {/* {dataObj?.imageUrl &&
                  detailFun(
                    "imageUrl",
                    `${dataObj?.imageUrl ? dataObj?.imageUrl : ""}`
                  )} */}
                {dataObj?.documentType === "Vise" && (
                  <>
                    {dataObj?.visaType &&
                      detailFun(
                        "Visa Type",
                        `${dataObj?.visaType ? dataObj?.visaType : ""}`
                      )}
                    {dataObj?.visaStatus &&
                      detailFun(
                        "Visa Status",
                        `${dataObj?.visaStatus ? dataObj?.visaStatus : ""}`
                      )}
                  </>
                )}
                {dataObj?.documentType === "Birth Certificate" && (
                  <>
                    {dataObj?.dob &&
                      detailFun(
                        "Date of Birth",
                        `${dataObj?.dob ? dataObj?.dob : ""}`
                      )}
                    {dataObj?.description &&
                      detailFun(
                        "Description",
                        `${dataObj?.description ? dataObj?.description : ""}`
                      )}
                  </>
                )}

                {dataObj?.documentType === "Citizenship" && (
                  <>
                    {dataObj?.citizenshipNo &&
                      detailFun(
                        "Citizenship Number",
                        `${
                          dataObj?.citizenshipNo ? dataObj?.citizenshipNo : ""
                        }`
                      )}
                    {dataObj?.citizenshipType &&
                      detailFun(
                        "Citizenship Type",
                        `${
                          dataObj?.citizenshipType
                            ? dataObj?.citizenshipType
                            : ""
                        }`
                      )}
                    {dataObj?.issuedDistrict &&
                      detailFun(
                        "Issued District",
                        `${
                          dataObj?.issuedDistrict ? dataObj?.issuedDistrict : ""
                        }`
                      )}
                    {dataObj?.issuedDate &&
                      detailFun(
                        "Issued Date",
                        `${dataObj?.issuedDate ? dataObj?.issuedDate : ""}`
                      )}
                    {/* {dataObj?.citizenshipFrontImage &&
                      detailFun(
                        "citizenshipFrontImage",
                        `${
                          dataObj?.citizenshipFrontImage
                            ? dataObj?.citizenshipFrontImage
                            : ""
                        }`
                      )} */}
                    {/* {dataObj?.citizenshipBackImage &&
                      detailFun(
                        "citizenshipBackImage",
                        `${
                          dataObj?.citizenshipBackImage
                            ? dataObj?.citizenshipBackImage
                            : ""
                        }`
                      )} */}
                    {dataObj?.isCitizenshipUploaded &&
                      detailFun(
                        "Is Citizenship Uploaded?",
                        twoTagResponses(
                          dataObj?.isCitizenshipUploaded,
                          true,
                          false
                        )
                      )}
                  </>
                )}

                {/* <Button type="primary" onClick={() => {}}>
                Unassign Inqury
              </Button> */}
              </div>
            </div>
          );
        })
      ) : (
        <div style={{ textAlign: "center" }}>No Documents availble.</div>
      )}
    </div>
  );
};

export default StudentDocuments;
