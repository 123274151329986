import React, { useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  message,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { allActions } from "../../../Redux/myActions";
import { useNavigate, useParams } from "react-router-dom";
import { BsConeStriped } from "react-icons/bs";
const { Option } = Select;

const SubscrptionAddons = ({
  subscriptionRecord,
  noOfUsers,
  isSubscriptionAddonsModalVisible,
  setIsSubscriptionAddonsModalVisible,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const [paymentMethodActive, setPaymentMethodActive] = useState("STRIPE");
  const [receiptNo, setReceiptNo] = useState(null);
  const actions = useDispatch();
  const [form] = Form.useForm();
  const subscriptionAddonsSubmitHandler = (value) => {
    const formData = {
      ...value,
    };

    actions(
      allActions(
        {
          subscriptionId: subscriptionRecord?.id,
          purchaseUserCount: noOfUsers,
          confirmationText: "",
          receiptNo: paymentMethodActive === "BANK_TRANSFER" ? receiptNo : null,
          purchaseMethod: paymentMethodActive,
        },
        {
          method: "post",
          endPoint: `purchase-subscription-of-client/${params.id}`,
          attempt: "PURCHASE_SUBSCRIPTION_REQUEST",
          success: "PURCHASE_SUBSCRIPTION_REQUEST_SUCCESS",
          failure: "PURCHASE_SUBSCRIPTION_REQUEST_FAILURE",
          //   navigateTo: null,
          successInternalState: (data) => {
            if (paymentMethodActive !== "STRIPE") {
              message.success("Success! Subscription package upgraded.");
              navigate("/client-management");
            }
          },
          saveBearerToken: true,
        }
      )
    );
    // dispatch({
    //   type: "PURCHASE_SUBSCRIPTION_REQUEST",
    //   payload: {
    //     subscriptionId: subscriptionRecord?.id,
    //     purchaseUserCount: noOfUsers,
    //     confirmationText: "",
    //   },
    // });
    // form.resetFields();
    setIsSubscriptionAddonsModalVisible(false);
  };
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  const detailFunFlexStart = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowFlexStart" style={{ gap: "2rem" }}>
          <span style={{ width: "20%" }}>{title}:</span>
          <span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  console.log("from subscriptionRecord ", subscriptionRecord);
  console.log("from noOfUsers ", noOfUsers);
  return (
    <Modal
      title="Proceed to Payment"
      open={isSubscriptionAddonsModalVisible}
      onCancel={() => {
        setIsSubscriptionAddonsModalVisible(false);
      }}
      footer={null}
      maskClosable={false}
      width={"50vw"}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <h3>Package Includes:</h3>
        {detailFunFlexStart(
          "Users",
          `${
            +noOfUsers > +subscriptionRecord.userCount
              ? `${subscriptionRecord.userCount} + ${
                  +noOfUsers - +subscriptionRecord.userCount
                } (package included + extra user)`
              : // +subscriptionRecord?.packagePrice
                +subscriptionRecord.userCount
          }`
        )}
        {detailFunFlexStart(
          "Basic package price",
          `$ ${subscriptionRecord?.packagePrice}`
        )}
        {detailFunFlexStart(
          "Extra price per user",
          `$ ${subscriptionRecord?.pricePerUser ?? 0}`
        )}

        {/* {detailFunFlexStart("Device Count", subscriptionRecord?.deviceCount)} */}
        {/* {detailFunFlexStart("Lead Forms", subscriptionRecord?.leadForms)}
        {detailFunFlexStart("Storage", subscriptionRecord?.storage)} */}
        <div style={{ width: "100%" }}>
          <div className="flexRowSpaceBetween">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            ></div>

            <span>
              ${subscriptionRecord?.packagePrice} +
              {+noOfUsers > +subscriptionRecord.userCount
                ? +noOfUsers - +subscriptionRecord?.userCount ?? 1
                : 0}{" "}
              * ${subscriptionRecord?.pricePerUser ?? 0} = $
              {+noOfUsers > +subscriptionRecord.userCount
                ? +subscriptionRecord?.packagePrice +
                  (+noOfUsers - +subscriptionRecord.userCount) *
                    +subscriptionRecord?.pricePerUser
                : +subscriptionRecord?.packagePrice}
            </span>
          </div>
          <Divider
            orientationMargin="0"
            style={{ margin: "0.5rem", color: "black" }}
          />
        </div>
        <div style={{ width: "100%", margin: "0 0 1rem 0" }}>
          <div className="flexRowFlexEnd" style={{ gap: "1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontWeight: "500",
              }}
            >
              Total amount:
            </div>

            <span style={{ fontWeight: "500" }}>
              {/* $ {+noOfUsers * +subscriptionRecord?.packagePrice} */}${" "}
              {+noOfUsers > +subscriptionRecord.userCount
                ? +subscriptionRecord?.packagePrice +
                  (+noOfUsers - +subscriptionRecord.userCount) *
                    subscriptionRecord?.pricePerUser
                : +subscriptionRecord?.packagePrice}
            </span>
          </div>
        </div>
      </div>
      <Form
        layout="vertical"
        onFinish={subscriptionAddonsSubmitHandler}
        colon={true}
        form={form}
      >
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              width: "30%",
              margin: "1rem 0 1rem 0",
              cursor: "pointer",

              border:
                paymentMethodActive === "STRIPE"
                  ? "2px solid blue"
                  : "2px solid #ccc",
              borderRadius: "0.5rem",
            }}
            onClick={() => {
              setPaymentMethodActive("STRIPE");
            }}
          >
            <Card bordered={false}>
              <div>
                <span className="paymentMethod"> Credit Card</span>
              </div>
            </Card>
          </div>
          <div
            style={{
              width: "30%",
              margin: "1rem 0 1rem 0",
              cursor: "pointer",

              border:
                paymentMethodActive === "BANK_TRANSFER"
                  ? "2px solid blue"
                  : "2px solid #ccc",
              borderRadius: "0.5rem",
            }}
            onClick={() => {
              setPaymentMethodActive("BANK_TRANSFER");
            }}
          >
            <Card bordered={false}>
              <div>
                <span className="paymentMethod">Bank Deposit</span>
              </div>
            </Card>
          </div>
          <div
            style={{
              width: "30%",
              margin: "1rem 0 1rem 0",
              cursor: "pointer",

              border:
                paymentMethodActive === "CASH_ON_DELIVERY"
                  ? "2px solid blue"
                  : "2px solid #ccc",
              borderRadius: "0.5rem",
            }}
            onClick={() => {
              setPaymentMethodActive("CASH_ON_DELIVERY");
            }}
          >
            <Card bordered={false}>
              <div>
                <span className="paymentMethod">Cash on Delivery</span>
              </div>
            </Card>
          </div>
        </div>
        {paymentMethodActive === "BANK_TRANSFER" && (
          <Form.Item label="Receipt No." name={"receipt"}>
            <Input
              placeholder="Receipt No."
              onChange={(e) => {
                setReceiptNo(e.target.value);
              }}
            />
          </Form.Item>
        )}

        <Form.Item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button type="primary" htmlType="submit">
            {paymentMethodActive === "STRIPE"
              ? "Proceed to Checkout"
              : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubscrptionAddons;
