import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  clients: {},
  clientInstitute: {},
  supportTicketsByClientID: {},
  accountDetailsByClientId: {},
};

const ClientReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_ALL_CLIENTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ALL_CLIENTS_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        clients: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ALL_CLIENTS_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_SUPPORT_TICKET_REQUEST_BY_CLIENT_ID": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_SUPPORT_TICKET_REQUEST_BY_CLIENT_ID_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        supportTicketsByClientID: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_SUPPORT_TICKET_REQUEST_BY_CLIENT_ID_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ACCOUNT_DETAILS_BY_CLIENT_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ACCOUNT_DETAILS_BY_CLIENT_ID_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        accountDetailsByClientId: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ACCOUNT_DETAILS_BY_CLIENT_ID_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_INSTITUTE_BY_CLIENT_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_INSTITUTE_BY_CLIENT_ID_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        clientInstitute: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_INSTITUTE_BY_CLIENT_ID_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "SEARCH_CLIENTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "SEARCH_CLIENTS_REQUEST_SUCCESS": {
      return {
        ...state,
        clients: action.payload,
        isLoading: false,
      };
    }
    case "SEARCH_CLIENTS_REQUEST_FAILURE": {
      action.payload?.data?.message
        ? message.error(action.payload?.data?.message)
        : message.error("Error! Unable to fetch clients.");

      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default ClientReducer;
