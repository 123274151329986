import { Button } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SiderDemo from "../components/Siderdemo";
import { allActions } from "../Redux/myActions";

const PaymentSuccess = ({ image, messageText1, messageText2 }) => {
  const actions = useDispatch();
  const navigate = useNavigate();
  const paymentId = new URLSearchParams(window.location.search).get(
    "paymentId"
  );
  useEffect(() => {
    if (paymentId) {
      actions(
        allActions(
          { paymentId: paymentId },
          {
            method: "post",
            endPoint: `/verify-purchase`,
            attempt: "VERIFY_SUBSCRIPTION_PURCHASE_REQUEST",
            success: "VERIFY_SUBSCRIPTION_PURCHASE_REQUEST_SUCCESS",
            failure: "VERIFY_SUBSCRIPTION_PURCHASE_REQUEST_FAILURE",
            //   navigateTo: null,
            //   successInternalState: (data) => {
            //     navigation.navigate("LeadDetails", { item });
            //   },
            saveBearerToken: true,
          }
        )
      );
    }
  }, [paymentId]);
  return (
    <SiderDemo>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "flex-start",
        }}
      >
        <div
          className="subscription_payment flipInX animated"
          style={{
            width: "35vw",
            height: "minContent",
            display: "flex",
            flexDirection: "column",
            marginTop: "4rem",
            borderRadius: "10px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
        >
          <div
            style={{
              padding: "1.5rem",
              width: "100%",
              backgroundColor: "#f0f2f5",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={image} alt="Green Tick" height={39} width={39} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem",
            }}
          >
            <p style={{ fontSize: "18px" }}>{messageText1}</p>
            <p style={{ fontSize: "18px" }}>{messageText2}</p>
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "30px",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Go To Dashboard
            </Button>
          </div>
        </div>
      </div>
    </SiderDemo>
  );
};

export default PaymentSuccess;
