import {
  Button,
  Card,
  Divider,
  Drawer,
  Image,
  Modal,
  Pagination,
  Popconfirm,
  Space,
  Table,
  Tag,
  Timeline,
  Tooltip,
} from "antd";
import React, { useEffect } from "react";
import {
  AiOutlineCloseCircle,
  AiOutlineEye,
  AiOutlineMail,
  AiOutlinePhone,
} from "react-icons/ai";
import client from "./../../../Assets/client.png";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { BsChatLeftText, BsReply } from "react-icons/bs";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  formattedDateTime,
  threeTagResponses,
  threeTagResponsesTransaction,
  twoTagResponses,
  twoTagResponsesWithStatuses,
} from "../../../Helpers/HelperFunction";
import { MdOutlineAssignmentTurnedIn, MdOutlinePending } from "react-icons/md";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { allActions } from "../../../Redux/myActions";
import TableSkeleton from "../../../Helpers/TableSkeleton/tableSkelaton";
import AssignedConsultancy from "../AssignedConsultancy/AssignedConsultancy";
import StudentAcademicQualifications from "../StudentAcademicQualifications/StudentAcademicQualifications";
import AssignConsultancy from "../AssignConsultancy";
const { confirm } = Modal;

const StudentEnquiryDetails = ({
  record,
  isStudentEnquiryDetailsModalVisible,
  setIsStudentEnquiryDetailsModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      studentEnquiryState: state.StudentInquiryReducer,
    };
  });

  const [current, setCurrent] = useState("StudentEnquiryDetails");
  const [isUpdateSupportModalVisible, setIsUpdateSupportModalVisible] =
    useState(false);
  const [isViewSupportModalVisible, setIsViewSupportModalVisible] =
    useState(false);
  const [
    isAssignSupportTicketModalVisible,
    setIsAssignSupportTicketModalVisible,
  ] = useState(false);
  const [isAssignConsultancyModalVisible, setIsAssignConsultancyModalVisible] =
    useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [supportRecord, setSupportRecord] = useState({});

  const actions = useDispatch();
  const fetchStudentEnquiryByID = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/getEnquiryById/${record?.enquiryId}`,
          attempt: "FETCH_STUDENT_ENQUIRY_BY_ID_REQUEST",
          success: "FETCH_STUDENT_ENQUIRY_BY_ID_REQUEST_SUCCESS",
          failure: "FETCH_STUDENT_ENQUIRY_BY_ID_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  const fetchAllStudentEnquiry = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/get-all-enquiry?page=${page}&pageSize=${pageSize}`,
          attempt: "FETCH_ALL_STUDENT_ENQUIRY_REQUEST",
          success: "FETCH_ALL_STUDENT_ENQUIRY_REQUEST_SUCCESS",
          failure: "FETCH_ALL_STUDENT_ENQUIRY_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  const cancelStudentEnquiry = () => {
    actions(
      allActions(
        {},
        {
          method: "put",
          endPoint: `/enquiry-cancel?enquiryID=${record?.enquiryId}`,
          attempt: "CANCEL_STUDENT_ENQUIRY_REQUEST",
          success: "CANCEL_STUDENT_ENQUIRY_REQUEST_SUCCESS",
          failure: "CANCEL_STUDENT_ENQUIRY_REQUEST_FAILURE",
          //   navigateTo: null,
          successInternalState: (data) => {
            fetchStudentEnquiryByID();
            fetchAllStudentEnquiry();
          },
          saveBearerToken: true,
        }
      )
    );
  };

  const onShowSizeChange = (current, pageSize) => {
    console.log("page changed", current, pageSize);
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  useEffect(() => {
    fetchStudentEnquiryByID();
  }, []);

  const StudentEnquiryDetailsTabList = [
    {
      key: "StudentEnquiryDetails",
      tab: "Enquiry Details",
    },
    {
      key: "englishQualifications",
      tab: "English Qualifications",
    },
    {
      key: "assignedConsultancies",
      tab: "Assigned Consultancies",
    },
  ];

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  const navigate = useNavigate();

  console.log("supportRecord from lead details", store?.studentEnquiryState);
  return (
    <Drawer
      title="Enquiry Details"
      open={isStudentEnquiryDetailsModalVisible}
      onClose={() => {
        setIsStudentEnquiryDetailsModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card
        loading={store?.studentEnquiryState?.isLoading}
        style={{ borderRadius: "0.3rem" }}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{
            gap: "1rem",
            alignItems: "stretch",
          }}
        >
          {store?.studentEnquiryState?.studentEnquiryById?.student
            ?.studentImage ? (
            <Image
              src={
                store?.studentEnquiryState?.studentEnquiryById?.student
                  ?.studentImage
              }
              className={"clientImage"}
            />
          ) : (
            <Image src={client} className={"clientImage"} />
          )}
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              position: "relative",
              width: "100%",
              gap: "0.5rem",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {`${
                store?.studentEnquiryState?.studentEnquiryById?.student
                  ?.fullName ?? ""
              }`}{" "}
              {`(Enquiry Id: ${
                store?.studentEnquiryState?.studentEnquiryById?.enquiryId ?? ""
              })`}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              {store?.studentEnquiryState?.studentEnquiryById?.student
                ?.phoneNumber && (
                <span className="flexRowWithGap">
                  <AiOutlinePhone
                    style={{
                      fontSize: "20",
                    }}
                  />{" "}
                  {store?.studentEnquiryState?.studentEnquiryById?.student
                    ?.phoneNumber
                    ? store?.studentEnquiryState?.studentEnquiryById?.student
                        ?.phoneNumber
                    : "N/A"}
                </span>
              )}
              {store?.studentEnquiryState?.studentEnquiryById?.student
                ?.address && (
                <span className="flexRowWithGap">
                  <GoLocation
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {store?.studentEnquiryState?.studentEnquiryById?.student
                    ?.address
                    ? store?.studentEnquiryState?.studentEnquiryById?.student
                        ?.address
                    : "N/A"}
                </span>
              )}
              {store?.studentEnquiryState?.studentEnquiryById?.student
                ?.email && (
                <span className="flexRowWithGap">
                  <AiOutlineMail
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {store?.studentEnquiryState?.studentEnquiryById?.student
                    ?.email
                    ? store?.studentEnquiryState?.studentEnquiryById?.student
                        ?.email
                    : "N/A"}
                </span>
              )}
            </div>
            <div>
              {/* <div>
                  <span className="profileTitle">Campaign:</span>
                  <span>
                    {store.leadState.leadById?.customerResponse?.campaign?.name ??
                      "N/A"}
                  </span>
                </div> */}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.5rem",
                // justifySelf:""
                position: "absolute",
                alignSelf: "flex-start",
                flexWrap: "wrap",
                bottom: 0,
              }}
            >
              {/*  <button
                    className="emailAndMessage"
                    onClick={() => {
                      setIsSendComposedEmailModalVisible(true);
                    }}
                  >
                    <AiOutlineMail />
                    <span>Email</span>
                  </button> */}
              {store?.studentEnquiryState?.studentEnquiryById
                ?.assignedStatus !== "CANCELLED" && (
                <Button
                  type="primary"
                  onClick={() => {
                    setIsAssignConsultancyModalVisible(true);
                  }}
                >
                  Assign Consultancy
                </Button>
              )}
              {store?.studentEnquiryState?.studentEnquiryById
                ?.assignedStatus !== "CANCELLED" && (
                <Button
                  onClick={() => {
                    confirm({
                      title: "Are you sure to cancel this Enquiry?",
                      icon: <ExclamationCircleOutlined />,
                      // onOk: {()=>{}}
                      okText: "YES",
                      cancelText: "NO",
                      onOk() {
                        cancelStudentEnquiry();
                      },
                    });
                  }}
                >
                  Cancel Enquiry
                </Button>
              )}
            </div>
            {store?.studentEnquiryState?.studentEnquiryById?.status && (
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
              >
                <Tag color="#108ee9">
                  {store?.studentEnquiryState?.studentEnquiryById?.status}
                </Tag>
              </div>
            )}
          </div>
        </div>
        <Card
          bordered={false}
          tabList={StudentEnquiryDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "StudentEnquiryDetails" && (
            <>
              <div
                style={{
                  padding: "0.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "row",
                  }}
                >
                  <Card
                    className="clientCard"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {detailFun(
                      "Current Location",
                      ` ${
                        store?.studentEnquiryState?.studentEnquiryById
                          ?.currentLocation ?? ""
                      }`
                    )}
                    {detailFun(
                      "Destination Location",
                      `${
                        store?.studentEnquiryState?.studentEnquiryById
                          ?.destinationLocation ?? ""
                      }`
                    )}

                    {detailFun(
                      "Purpose",
                      `${
                        store?.studentEnquiryState?.studentEnquiryById
                          ?.purpose ?? ""
                      }`
                    )}
                    {store?.studentEnquiryState?.studentEnquiryById?.purpose?.toUpperCase() ===
                      "MIGRATION" && (
                      <>
                        {detailFun(
                          "Visa Type",
                          `${
                            store?.studentEnquiryState?.studentEnquiryById
                              ?.visaType ?? ""
                          }`
                        )}
                        {detailFun(
                          "Migration Class",
                          `${
                            store?.studentEnquiryState?.studentEnquiryById
                              ?.migrationClass ?? ""
                          }`
                        )}
                        {detailFun(
                          "Relation",
                          `${
                            store?.studentEnquiryState?.studentEnquiryById
                              ?.relation ?? ""
                          }`
                        )}
                        {detailFun(
                          "Have Kids",
                          `${
                            store?.studentEnquiryState?.studentEnquiryById
                              ?.haveKids ?? ""
                          }`
                        )}

                        {detailFun(
                          "No. of Kids",
                          `${
                            store?.studentEnquiryState?.studentEnquiryById
                              ?.noOfKids ?? ""
                          }`
                        )}
                        {detailFun(
                          "Name of Kid",
                          `${
                            store?.studentEnquiryState?.studentEnquiryById
                              ?.nameOfKids ?? ""
                          }`
                        )}
                      </>
                    )}
                    {store?.studentEnquiryState?.studentEnquiryById?.purpose?.toUpperCase() ===
                      "EDUCATION" && (
                      <>
                        {" "}
                        {detailFun(
                          "Degree",
                          `${
                            store?.studentEnquiryState?.studentEnquiryById
                              ?.degree ?? ""
                          }`
                        )}
                        {detailFun(
                          "Field",
                          `${
                            store?.studentEnquiryState?.studentEnquiryById
                              ?.field ?? ""
                          }`
                        )}
                        {detailFun(
                          "Course",
                          `${
                            store?.studentEnquiryState?.studentEnquiryById
                              ?.courses ?? ""
                          }`
                        )}
                        {detailFun(
                          "Intake",
                          `${
                            store?.studentEnquiryState?.studentEnquiryById
                              ?.intake ?? ""
                          }`
                        )}
                        {detailFun(
                          "Preferred College",
                          `${
                            store?.studentEnquiryState?.studentEnquiryById
                              ?.preferredCollege ?? ""
                          }`
                        )}
                        {detailFun(
                          "Price Range",
                          `${
                            store?.studentEnquiryState?.studentEnquiryById
                              ?.priceRange ?? ""
                          }`
                        )}
                        {detailFun(
                          "Scholarship",
                          `${
                            store?.studentEnquiryState?.studentEnquiryById
                              ?.scholarship ?? ""
                          }`
                        )}
                      </>
                    )}

                    {record?.subscription &&
                      record?.subscription?.isFree &&
                      detailFun(
                        "Is Free?",

                        twoTagResponses(
                          record?.subscription?.isFree,
                          true,
                          false
                        ) ?? ""
                      )}
                  </Card>
                  <Card
                    className="clientCard"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {detailFun(
                      "Academic Qualification",
                      `${
                        store?.studentEnquiryState?.studentEnquiryById
                          ?.academicQualification ?? ""
                      }`
                    )}
                    {detailFun(
                      "Institute",
                      `${
                        store?.studentEnquiryState?.studentEnquiryById
                          ?.institute ?? ""
                      }`
                    )}
                    {detailFun(
                      "Country",
                      `${
                        store?.studentEnquiryState?.studentEnquiryById
                          ?.country ?? ""
                      }`
                    )}
                    {detailFun(
                      "Year Of Commencement",
                      `${
                        store?.studentEnquiryState?.studentEnquiryById
                          ?.yearOfCommencement ?? ""
                      }`
                    )}
                    {detailFun(
                      "Year Of Completion",
                      `${
                        store?.studentEnquiryState?.studentEnquiryById
                          ?.yearOfCompletion ?? ""
                      }`
                    )}
                    {store?.studentEnquiryState?.studentEnquiryById
                      ?.obtainedPercentage &&
                      detailFun(
                        "Obtained Score",
                        `${
                          store?.studentEnquiryState?.studentEnquiryById
                            ?.obtainedPercentage ?? ""
                        }`
                      )}
                    {store?.studentEnquiryState?.studentEnquiryById
                      ?.obtainedScore &&
                      detailFun(
                        "Obtained Score",
                        `${
                          store?.studentEnquiryState?.studentEnquiryById
                            ?.obtainedScore ?? ""
                        }`
                      )}
                    {detailFun(
                      "Obtained Score Type",
                      `${
                        store?.studentEnquiryState?.studentEnquiryById
                          ?.percentageOrGPA ?? ""
                      }`
                    )}
                    {detailFun(
                      "Additional Notes",
                      `${
                        store?.studentEnquiryState?.studentEnquiryById
                          ?.additionalNotes ?? ""
                      }`
                    )}
                    {detailFun(
                      "Assigned Status",
                      threeTagResponses(
                        store?.studentEnquiryState?.studentEnquiryById
                          ?.assignedStatus,
                        "UNASSIGNED",
                        "UNASSIGNED",
                        "CANCELLED"
                      )
                    )}
                    {detailFun(
                      "Remind Me?",
                      twoTagResponses(
                        store?.studentEnquiryState?.studentEnquiryById
                          ?.remindMe,
                        true,
                        false
                      )
                    )}
                    {detailFun(
                      "Type",
                      `${
                        store?.studentEnquiryState?.studentEnquiryById?.type ??
                        ""
                      }`
                    )}
                  </Card>
                </div>
              </div>
            </>
          )}
          {current === "englishQualifications" && (
            <>
              <StudentAcademicQualifications
                record={store?.studentEnquiryState?.studentEnquiryById}
              />
            </>
          )}
          {current === "assignedConsultancies" && (
            <>
              <AssignedConsultancy
                record={store?.studentEnquiryState?.studentEnquiryById}
              />
            </>
          )}
        </Card>
      </Card>
      {isAssignConsultancyModalVisible && (
        <AssignConsultancy
          record={store?.studentEnquiryState?.studentEnquiryById}
          isAssignConsultancyModalVisible={isAssignConsultancyModalVisible}
          setIsAssignConsultancyModalVisible={
            setIsAssignConsultancyModalVisible
          }
        />
      )}
    </Drawer>
  );
};

export default StudentEnquiryDetails;
