import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  visaSubclass: "",
  migrationVisaSubclass: "",
};

const VisaSubclassReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADD_VISA_SUBCLASS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_VISA_SUBCLASS_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_VISA_SUBCLASS_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_VISA_SUBCLASS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_VISA_SUBCLASS_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        visaSubclass: action?.payload,
      };
    }
    case "FETCH_VISA_SUBCLASS_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_MIGRATION_VISA_SUBCLASS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_MIGRATION_VISA_SUBCLASS_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        migrationVisaSubclass: action?.payload,
      };
    }
    case "FETCH_MIGRATION_VISA_SUBCLASS_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "DELETE_EDUCATION_VISAS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "DELETE_EDUCATION_VISAS_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_EDUCATION_VISAS_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default VisaSubclassReducer;
