import React from "react";
import PaymentSuccess from "./PaymentSuccess";
import redCross from "./../Assets/redCross.png";
const SubscriptionFailure = () => {
  return (
    <PaymentSuccess
      image={redCross}
      messageText1="Error !!!"
      messageText2="Your Subscription plan couldn't be granted."
    />
  );
};

export default SubscriptionFailure;
