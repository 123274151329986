import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "../../../Redux/myActions";

const AddEditDegree = ({
  record,
  modalType,
  setIsDegreeModalVisible,
  isDegreeModalVisible,
  fetchAdminDegree,
}) => {
  const actions = useDispatch();
  const [form] = Form.useForm();

  const addDegreeSubmitHandler = (value) => {
    actions(
      allActions(value, {
        method: modalType === "Add" ? "post" : "put",
        endPoint:
          modalType === "Add" ? "/degree/add" : `/degree/update/${record?.id}`,
        attempt: "ADD_DEGREE_REQUEST",
        success: "ADD_DEGREE_REQUEST_SUCCESS",
        failure: "ADD_DEGREE_REQUEST_FAILURE",
        saveBearerToken: false,
        successInternalState: () => {
          setIsDegreeModalVisible(false);
          fetchAdminDegree();
        },
      })
    );

    setIsDegreeModalVisible(false);
  };
  return (
    <Modal
      title={`${modalType} Degree`}
      open={isDegreeModalVisible}
      onCancel={() => {
        setIsDegreeModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addDegreeSubmitHandler}
        colon={true}
        form={form}
        initialValues={{
          degreeName: record?.degreeName,
        }}
      >
        <Form.Item
          label="Degree Name"
          name={"degreeName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter degree name!",
            },
          ]}
        >
          <Input placeholder="Degree Name" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            {modalType} Degree
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditDegree;
