import { Button, Divider } from "antd";
import React from "react";

const AssignedConsultancy = ({ record }) => {
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  return (
    <div>
      {record?.lists?.length > 0 ? (
        record?.lists?.map((dataObj) => {
          return (
            <div
              key={dataObj.id}
              style={{
                backgroundColor: "#eeeeee55",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                marginBottom: "0.5rem",
              }}
            >
              <div>
                {detailFun(
                  "Consultancy ID",
                  `${dataObj?.clientId?.id ? dataObj?.clientId?.id : ""}`
                )}
                {detailFun(
                  "Consultancy Name",
                  `${
                    dataObj?.clientId?.companyName
                      ? dataObj?.clientId?.companyName
                      : ""
                  }`
                )}
                {detailFun(
                  "Company Registration Number",
                  `${
                    dataObj?.clientId?.companyRegistrationNumber
                      ? dataObj?.clientId?.companyRegistrationNumber
                      : ""
                  }`
                )}
                {detailFun(
                  "Address",
                  `${
                    dataObj?.clientId?.companyAddress
                      ? dataObj?.clientId?.companyAddress
                      : ""
                  }`
                )}
                {/* <Button type="primary" onClick={() => {}}>
                Unassign Inqury
              </Button> */}
              </div>
            </div>
          );
        })
      ) : (
        <div style={{ textAlign: "center" }}>
          No assigned consultancy availble.
        </div>
      )}
    </div>
  );
};

export default AssignedConsultancy;
