import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../components/Siderdemo";
import { allActions } from "../../Redux/myActions";
import { AiOutlineCopy, AiOutlineEye, AiOutlineMail } from "react-icons/ai";
import { threeTagResponses } from "../../Helpers/HelperFunction";
import { FiFlag } from "react-icons/fi";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import StudentEnquiryDetails from "./StudentEnquiryDetails/StudentEnquiryDetails";
import { SiGoogleclassroom } from "react-icons/si";
import AssignConsultancy from "./AssignConsultancy";
const columns = [
  {
    title: "Enquiry Id",
    dataIndex: "enquiryId",
    align: "center",
  },
  {
    title: "Name",
    dataIndex: "Name",
    align: "center",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    align: "center",
  },
  {
    title: "Purpose/Country",
    dataIndex: "purpose",
    align: "center",
  },
  {
    title: "Current Location",
    dataIndex: "currentLocation",
    align: "center",
  },
  {
    title: "Destination Location",
    dataIndex: "destinationLocation",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Assigned Status",
    dataIndex: "assignedStatus",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const StudentEnquiry = () => {
  const store = useSelector((state) => {
    return {
      studentEnquiryState: state.StudentInquiryReducer,
    };
  });
  const [isAddStudentEnquiryModalVisible, setIsAddStudentEnquiryModalVisible] =
    useState(false);
  const [
    isUpdateStudentEnquiryModalVisible,
    setIsUpdateStudentEnquiryModalVisible,
  ] = useState(false);
  const [
    isStudentEnquiryDetailsModalVisible,
    setIsStudentEnquiryDetailsModalVisible,
  ] = useState(false);
  const [isAssignConsultancyModalVisible, setIsAssignConsultancyModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [StudentEnquiryType, setStudentEnquiryType] = useState("ALL");
  const action = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };

  const fetchAllStudentEnquiry = () => {
    action(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/get-all-enquiry?page=${page}&pageSize=${pageSize}`,
          attempt: "FETCH_ALL_STUDENT_ENQUIRY_REQUEST",
          success: "FETCH_ALL_STUDENT_ENQUIRY_REQUEST_SUCCESS",
          failure: "FETCH_ALL_STUDENT_ENQUIRY_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  const fetchStudentEnquiryOfStatus = () => {
    action(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/enquiry/get-by-assignedStatus?assignedStatus=${StudentEnquiryType}&page=${page}&pageSize=${pageSize}`,
          attempt: "FETCH_ENQUIRY_OF_STATUS_REQUEST",
          success: "FETCH_ENQUIRY_OF_STATUS_REQUEST_SUCCESS",
          failure: "FETCH_ENQUIRY_OF_STATUS_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };

  useEffect(() => {
    if (StudentEnquiryType === "ALL") {
      fetchAllStudentEnquiry();
    } else {
      fetchStudentEnquiryOfStatus();
    }
  }, [StudentEnquiryType]);
  let data;
  if (StudentEnquiryType === "ALL") {
    data = store.studentEnquiryState?.allStudentEnquiry?.data?.map(
      (dataObj) => {
        return {
          key: dataObj.id,
          id: dataObj.id,
          enquiryId: dataObj?.enquiryId,
          Name: (
            <Tooltip title="View Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsStudentEnquiryDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  {dataObj?.student?.fullName}
                </div>
              </a>
            </Tooltip>
          ),
          contact: (
            <div className="flexColumnWithoutStyle">
              {dataObj?.student?.emailId !== "" &&
              dataObj?.student?.emailId !== null &&
              dataObj?.student?.emailId !== undefined ? (
                <Tooltip title="Send Email">
                  <a>
                    <div className="bordered">
                      <div
                        className="flexRowWithoutStyle"
                        style={{ gap: "2px", color: "000", color: "000" }}
                      >
                        <AiOutlineMail />
                        <a href={`mailto:${dataObj?.student?.email}`}>
                          {dataObj?.student?.emailId ?? "N/A"}
                        </a>
                      </div>
                    </div>
                  </a>
                </Tooltip>
              ) : null}
              {dataObj?.student?.phoneNumber !== "" &&
              dataObj?.student?.phoneNumber !== null &&
              dataObj?.student?.phoneNumber !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000" }}
                >
                  <CiMobile3 />
                  <a href={`tel:${dataObj?.student?.phoneNumber}`}>
                    {dataObj?.student?.phoneNumber ?? "N/A"}
                  </a>
                </div>
              ) : null}
            </div>
          ),
          purpose: (
            <div className="flexColumnWithoutStyle">
              {dataObj?.purpose !== "" &&
              dataObj?.purpose !== null &&
              dataObj?.purpose !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000", color: "000" }}
                >
                  <AiOutlineCopy />
                  <span>{dataObj?.purpose ?? "N/A"}</span>
                </div>
              ) : null}
              {dataObj?.country !== "" &&
              dataObj?.country !== null &&
              dataObj?.country !== undefined ? (
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "2px", color: "000" }}
                >
                  <FiFlag />
                  <span>{dataObj?.country ?? "N/A"}</span>
                </div>
              ) : null}
            </div>
          ),
          currentLocation: dataObj?.currentLocation ?? "N/A",
          destinationLocation: dataObj?.destinationLocation ?? "N/A",
          status: dataObj?.status
            ? threeTagResponses(
                dataObj?.status,
                "PENDING",
                "IN_PROGRESS",
                "COMPLETED"
              )
            : "N/A",
          assignedStatus: dataObj?.assignedStatus
            ? threeTagResponses(
                dataObj?.assignedStatus === "CANCELLED"
                  ? "CANCELLED"
                  : dataObj?.lists?.length > 0
                  ? "ASSIGNED"
                  : "UNASSIGNED",
                "CANCELLED",
                "ASSIGNED",
                "UNASSIGNED"
              )
            : "N/A",
          actions: (
            <Space size="middle">
              {dataObj?.assignedStatus !== "CANCELLED" && (
                <Tooltip title="Assign Consultancies">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setIsAssignConsultancyModalVisible(true);
                        setRecord(dataObj);
                      }}
                    >
                      <SiGoogleclassroom />
                    </div>
                  </a>
                </Tooltip>
              )}
              <Tooltip title="View Details">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStudentEnquiryDetailsModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineEye />
                  </div>
                </a>
              </Tooltip>
            </Space>
          ),
        };
      }
    );
  } else {
    data = store.studentEnquiryState?.allStudentEnquiry?.data?.map(
      (dataObj) => {
        if (
          StudentEnquiryType === "ASSIGNED" &&
          dataObj?.lists?.length > 0 &&
          dataObj?.assignedStatus !== "CANCELLED"
        ) {
          return {
            key: dataObj.id,
            id: dataObj.id,
            enquiryId: dataObj?.enquiryId,
            Name: dataObj?.student?.fullName,
            contact: (
              <div className="flexColumnWithoutStyle">
                {dataObj?.student?.emailId !== "" &&
                dataObj?.student?.emailId !== null &&
                dataObj?.student?.emailId !== undefined ? (
                  <Tooltip title="Send Email">
                    <a>
                      <div className="bordered">
                        <div
                          className="flexRowWithoutStyle"
                          style={{ gap: "2px", color: "000", color: "000" }}
                        >
                          <AiOutlineMail />
                          <a href={`mailto:${dataObj?.student?.email}`}>
                            {dataObj?.student?.emailId ?? "N/A"}
                          </a>
                        </div>
                      </div>
                    </a>
                  </Tooltip>
                ) : null}
                {dataObj?.student?.phoneNumber !== "" &&
                dataObj?.student?.phoneNumber !== null &&
                dataObj?.student?.phoneNumber !== undefined ? (
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "2px", color: "000" }}
                  >
                    <CiMobile3 />
                    <a href={`tel:${dataObj?.student?.phoneNumber}`}>
                      {dataObj?.student?.phoneNumber ?? "N/A"}
                    </a>
                  </div>
                ) : null}
              </div>
            ),
            purpose: (
              <div className="flexColumnWithoutStyle">
                {dataObj?.purpose !== "" &&
                dataObj?.purpose !== null &&
                dataObj?.purpose !== undefined ? (
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "2px", color: "000", color: "000" }}
                  >
                    <AiOutlineCopy />
                    <span>{dataObj?.purpose ?? "N/A"}</span>
                  </div>
                ) : null}
                {dataObj?.country !== "" &&
                dataObj?.country !== null &&
                dataObj?.country !== undefined ? (
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "2px", color: "000" }}
                  >
                    <FiFlag />
                    <span>{dataObj?.country ?? "N/A"}</span>
                  </div>
                ) : null}
              </div>
            ),
            currentLocation: dataObj?.currentLocation ?? "N/A",
            destinationLocation: dataObj?.destinationLocation ?? "N/A",
            status: dataObj?.status
              ? threeTagResponses(
                  dataObj?.status,
                  "PENDING",
                  "UNASSIGNED",
                  "CANCELLED"
                )
              : "N/A",
            assignedStatus: dataObj?.assignedStatus
              ? threeTagResponses(
                  dataObj?.assignedStatus === "CANCELLED"
                    ? "CANCELLED"
                    : dataObj?.lists?.length > 0
                    ? "ASSIGNED"
                    : "UNASSIGNED",
                  "CANCELLED",
                  "ASSIGNED",
                  "UNASSIGNED"
                )
              : "N/A",
            actions: (
              <Space size="middle">
                {dataObj?.assignedStatus !== "CANCELLED" && (
                  <Tooltip title="Assign Consultancies">
                    <a>
                      <div
                        className="bordered"
                        onClick={() => {
                          setIsAssignConsultancyModalVisible(true);
                          setRecord(dataObj);
                        }}
                      >
                        <SiGoogleclassroom />
                      </div>
                    </a>
                  </Tooltip>
                )}
                <Tooltip title="View Details">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setIsStudentEnquiryDetailsModalVisible(true);
                        setRecord(dataObj);
                      }}
                    >
                      <AiOutlineEye />
                    </div>
                  </a>
                </Tooltip>
              </Space>
            ),
          };
        } else if (
          StudentEnquiryType === "UNASSIGNED" &&
          dataObj?.lists?.length === 0
        ) {
          return {
            key: dataObj.id,
            id: dataObj.id,
            enquiryId: dataObj?.enquiryId,
            Name: dataObj?.student?.fullName,
            contact: (
              <div className="flexColumnWithoutStyle">
                {dataObj?.student?.emailId !== "" &&
                dataObj?.student?.emailId !== null &&
                dataObj?.student?.emailId !== undefined ? (
                  <Tooltip title="Send Email">
                    <a>
                      <div className="bordered">
                        <div
                          className="flexRowWithoutStyle"
                          style={{ gap: "2px", color: "000", color: "000" }}
                        >
                          <AiOutlineMail />
                          <a href={`mailto:${dataObj?.student?.email}`}>
                            {dataObj?.student?.emailId ?? "N/A"}
                          </a>
                        </div>
                      </div>
                    </a>
                  </Tooltip>
                ) : null}
                {dataObj?.student?.phoneNumber !== "" &&
                dataObj?.student?.phoneNumber !== null &&
                dataObj?.student?.phoneNumber !== undefined ? (
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "2px", color: "000" }}
                  >
                    <CiMobile3 />
                    <a href={`tel:${dataObj?.student?.phoneNumber}`}>
                      {dataObj?.student?.phoneNumber ?? "N/A"}
                    </a>
                  </div>
                ) : null}
              </div>
            ),
            purpose: (
              <div className="flexColumnWithoutStyle">
                {dataObj?.purpose !== "" &&
                dataObj?.purpose !== null &&
                dataObj?.purpose !== undefined ? (
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "2px", color: "000", color: "000" }}
                  >
                    <AiOutlineCopy />
                    <span>{dataObj?.purpose ?? "N/A"}</span>
                  </div>
                ) : null}
                {dataObj?.country !== "" &&
                dataObj?.country !== null &&
                dataObj?.country !== undefined ? (
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "2px", color: "000" }}
                  >
                    <FiFlag />
                    <span>{dataObj?.country ?? "N/A"}</span>
                  </div>
                ) : null}
              </div>
            ),
            currentLocation: dataObj?.currentLocation ?? "N/A",
            destinationLocation: dataObj?.destinationLocation ?? "N/A",
            status: dataObj?.status
              ? threeTagResponses(
                  dataObj?.status,
                  "PENDING",
                  "UNASSIGNED",
                  "CANCELLED"
                )
              : "N/A",
            assignedStatus: dataObj?.assignedStatus
              ? threeTagResponses(
                  dataObj?.assignedStatus === "CANCELLED"
                    ? "CANCELLED"
                    : dataObj?.lists?.length > 0
                    ? "ASSIGNED"
                    : "UNASSIGNED",
                  "CANCELLED",
                  "ASSIGNED",
                  "UNASSIGNED"
                )
              : "N/A",
            actions: (
              <Space size="middle">
                {dataObj?.assignedStatus !== "CANCELLED" && (
                  <Tooltip title="Assign Consultancies">
                    <a>
                      <div
                        className="bordered"
                        onClick={() => {
                          setIsAssignConsultancyModalVisible(true);
                          setRecord(dataObj);
                        }}
                      >
                        <SiGoogleclassroom />
                      </div>
                    </a>
                  </Tooltip>
                )}
                <Tooltip title="View Details">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setIsStudentEnquiryDetailsModalVisible(true);
                        setRecord(dataObj);
                      }}
                    >
                      <AiOutlineEye />
                    </div>
                  </a>
                </Tooltip>
              </Space>
            ),
          };
        } else if (
          StudentEnquiryType === "CANCELLED" &&
          dataObj?.assignedStatus === "CANCELLED"
        ) {
          return {
            key: dataObj.id,
            id: dataObj.id,
            enquiryId: dataObj?.enquiryId,
            Name: dataObj?.student?.fullName,
            contact: (
              <div className="flexColumnWithoutStyle">
                {dataObj?.student?.emailId !== "" &&
                dataObj?.student?.emailId !== null &&
                dataObj?.student?.emailId !== undefined ? (
                  <Tooltip title="Send Email">
                    <a>
                      <div className="bordered">
                        <div
                          className="flexRowWithoutStyle"
                          style={{ gap: "2px", color: "000", color: "000" }}
                        >
                          <AiOutlineMail />
                          <a href={`mailto:${dataObj?.student?.email}`}>
                            {dataObj?.student?.emailId ?? "N/A"}
                          </a>
                        </div>
                      </div>
                    </a>
                  </Tooltip>
                ) : null}
                {dataObj?.student?.phoneNumber !== "" &&
                dataObj?.student?.phoneNumber !== null &&
                dataObj?.student?.phoneNumber !== undefined ? (
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "2px", color: "000" }}
                  >
                    <CiMobile3 />
                    <a href={`tel:${dataObj?.student?.phoneNumber}`}>
                      {dataObj?.student?.phoneNumber ?? "N/A"}
                    </a>
                  </div>
                ) : null}
              </div>
            ),
            purpose: (
              <div className="flexColumnWithoutStyle">
                {dataObj?.purpose !== "" &&
                dataObj?.purpose !== null &&
                dataObj?.purpose !== undefined ? (
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "2px", color: "000", color: "000" }}
                  >
                    <AiOutlineCopy />
                    <span>{dataObj?.purpose ?? "N/A"}</span>
                  </div>
                ) : null}
                {dataObj?.country !== "" &&
                dataObj?.country !== null &&
                dataObj?.country !== undefined ? (
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "2px", color: "000" }}
                  >
                    <FiFlag />
                    <span>{dataObj?.country ?? "N/A"}</span>
                  </div>
                ) : null}
              </div>
            ),
            currentLocation: dataObj?.currentLocation ?? "N/A",
            destinationLocation: dataObj?.destinationLocation ?? "N/A",
            status: dataObj?.status
              ? threeTagResponses(
                  dataObj?.status,
                  "PENDING",
                  "UNASSIGNED",
                  "CANCELLED"
                )
              : "N/A",
            assignedStatus: dataObj?.assignedStatus
              ? threeTagResponses(
                  dataObj?.assignedStatus === "CANCELLED"
                    ? "CANCELLED"
                    : dataObj?.lists?.length > 0
                    ? "ASSIGNED"
                    : "UNASSIGNED",
                  "CANCELLED",
                  "ASSIGNED",
                  "UNASSIGNED"
                )
              : "N/A",
            actions: (
              <Space size="middle">
                {dataObj?.assignedStatus !== "CANCELLED" && (
                  <Tooltip title="Assign Consultancies">
                    <a>
                      <div
                        className="bordered"
                        onClick={() => {
                          setIsAssignConsultancyModalVisible(true);
                          setRecord(dataObj);
                        }}
                      >
                        <SiGoogleclassroom />
                      </div>
                    </a>
                  </Tooltip>
                )}
                <Tooltip title="View Details">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        setIsStudentEnquiryDetailsModalVisible(true);
                        setRecord(dataObj);
                      }}
                    >
                      <AiOutlineEye />
                    </div>
                  </a>
                </Tooltip>
              </Space>
            ),
          };
        } else {
          return;
        }
        return;
      }
    );
  }
  const filteredData = data?.filter((dataObj) => {
    return dataObj !== undefined;
  });
  console.log("from StudentEnquiry state", store.studentEnquiryState);
  console.log("from StudentEnquiry data", data);
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>ALL</div>
                  </div>
                ),
                value: "ALL",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>UNASSIGNED</div>
                  </div>
                ),
                value: "UNASSIGNED",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>ASSIGNED</div>
                  </div>
                ),
                value: "ASSIGNED",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>CANCELLED</div>
                  </div>
                ),
                value: "CANCELLED",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setStudentEnquiryType(value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {store.studentEnquiryState?.isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <Table
                bordered
                columns={columns}
                dataSource={filteredData}
                pagination={false}
              />

              {StudentEnquiryType === "ALL" &&
              store.studentEnquiryState?.allStudentEnquiry?.totalData ? (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={
                    store.studentEnquiryState?.allStudentEnquiry?.totalPage * 10
                  }
                />
              ) : (
                ""
              )}
              {StudentEnquiryType !== "ALL" &&
              store.studentEnquiryState?.studentEnquiryOfStatus?.totalData ? (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={
                    store.studentEnquiryState?.studentEnquiryOfStatus
                      ?.totalPage * 10
                  }
                />
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
      {isStudentEnquiryDetailsModalVisible && (
        <StudentEnquiryDetails
          record={record}
          isStudentEnquiryDetailsModalVisible={
            isStudentEnquiryDetailsModalVisible
          }
          setIsStudentEnquiryDetailsModalVisible={
            setIsStudentEnquiryDetailsModalVisible
          }
        />
      )}
      {isAssignConsultancyModalVisible && (
        <AssignConsultancy
          record={record}
          isAssignConsultancyModalVisible={isAssignConsultancyModalVisible}
          setIsAssignConsultancyModalVisible={
            setIsAssignConsultancyModalVisible
          }
        />
      )}
    </SiderDemo>
  );
};

export default StudentEnquiry;
