import { Card, Divider } from "antd";
import React from "react";
import { twoTagResponses } from "../../../Helpers/HelperFunction";

const StudentAcademicQualifications = ({ record }) => {
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  return (
    <Card
      className="clientCard"
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {detailFun(
        "Completed IELTS?",
        twoTagResponses(record?.completedIelts, true, false)
      )}
      {record?.completedIelts &&
        detailFun("IELTS Total Score", `${record?.ieltsTotalScore ?? ""}`)}
      {record?.completedIelts &&
        detailFun("IELTS Listening", `${record?.ieltsListening ?? ""}`)}
      {record?.completedIelts &&
        detailFun("IELTS Reading", `${record?.ieltsReading ?? ""}`)}
      {record?.completedIelts &&
        detailFun("IELTS Writing", `${record?.ieltsWriting ?? ""}`)}
      {record?.completedIelts &&
        detailFun("IELTS Speaking", `${record?.ieltsSpeaking ?? ""}`)}
      {detailFun(
        "Completed TOFEL?",
        twoTagResponses(record?.completedTofel, true, false)
      )}
      {record?.completedTofel &&
        detailFun("TOFEL Total Score", `${record?.tofelTotalScore ?? ""}`)}
      {detailFun(
        "Completed PTE?",
        twoTagResponses(record?.completedPte, true, false)
      )}
      {record?.completedPte &&
        detailFun("PTE Total Score", `${record?.pteTotalScore ?? ""}`)}
      {record?.completedPte &&
        detailFun("PTE Listening", `${record?.pteListening ?? ""}`)}
      {record?.completedPte &&
        detailFun("PTE Reading", `${record?.pteReading ?? ""}`)}
      {record?.completedPte &&
        detailFun("PTE Writing", `${record?.pteWriting ?? ""}`)}
      {record?.completedPte &&
        detailFun("PTE Speaking", `${record?.pteSpeaking ?? ""}`)}
      {detailFun(
        "Completed SAT?",
        twoTagResponses(record?.completedSat, true, false)
      )}
      {record?.completedSat &&
        detailFun("SAT Score", `${record?.satScore ?? ""}`)}
      {detailFun(
        "Completed GRE?",
        twoTagResponses(record?.completedGre, true, false)
      )}
      {record?.completedGre &&
        detailFun("GRE Verbal Score", `${record?.greVerbalScore ?? ""}`)}
      {record?.completedGre &&
        detailFun(
          "GRE Quantitative Score",
          `${record?.greQuantitativeScore ?? ""}`
        )}
      {record?.completedGre &&
        detailFun("GRE Writing Score", `${record?.greWritingScore ?? ""}`)}
    </Card>
  );
};

export default StudentAcademicQualifications;
