import { Button, Divider } from "antd";
import React from "react";

const AcademicQualifications = ({ record }) => {
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  return (
    <div>
      {record?.length > 0 ? (
        record?.map((dataObj) => {
          return (
            <div
              key={dataObj.id}
              style={{
                backgroundColor: "#eeeeee55",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                marginBottom: "0.5rem",
              }}
            >
              <div>
                {detailFun(
                  "Qualification",
                  `${dataObj?.qualification ? dataObj?.qualification : ""}`
                )}
                {detailFun(
                  "Institute",
                  `${dataObj?.institute ? dataObj?.institute : ""}`
                )}
                {detailFun(
                  "Country",
                  `${dataObj?.country ? dataObj?.country : ""}`
                )}

                {detailFun(
                  "Year of Commencement",
                  `${
                    dataObj?.yearOfCommencement
                      ? dataObj?.yearOfCommencement
                      : ""
                  }`
                )}
                {detailFun(
                  "Year of Completion",
                  `${
                    dataObj?.yearOfCompletion ? dataObj?.yearOfCompletion : ""
                  }`
                )}

                {detailFun(
                  "Obtained Score",
                  `${
                    dataObj?.obtainedScore
                      ? `${dataObj?.obtainedScore} ${
                          dataObj?.percentageOrGPA.toUpperCase() === "GPA"
                            ? "GPA"
                            : dataObj?.percentageOrGPA.toUpperCase() ===
                              "PERCENTAGE"
                            ? "%"
                            : ""
                        }`
                      : dataObj?.obtainedPercentage
                      ? `${dataObj?.obtainedPercentage} ${
                          dataObj?.percentageOrGPA.toUpperCase() === "GPA"
                            ? "GPA"
                            : dataObj?.percentageOrGPA.toUpperCase() ===
                              "PERCENTAGE"
                            ? "%"
                            : ""
                        }`
                      : ""
                  }`
                )}
                {/* <Button type="primary" onClick={() => {}}>
                Unassign Inqury
              </Button> */}
              </div>
            </div>
          );
        })
      ) : (
        <div style={{ textAlign: "center" }}>
          No academic qulifications availble.
        </div>
      )}
    </div>
  );
};

export default AcademicQualifications;
