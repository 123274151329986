import { Button, Divider } from "antd";
import React from "react";

const Experience = ({ record }) => {
  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  return (
    <div>
      {record?.length>0?record?.map((dataObj) => {
        return (
          <div
            key={dataObj.id}
            style={{
              backgroundColor: "#eeeeee55",
              borderRadius: "0.5rem",
              padding: "0.5rem",
              marginBottom: "0.5rem",
            }}
          >
            <div>
              {detailFun(
                "Organisation Name",
                `${dataObj?.organisationName ? dataObj?.organisationName : ""}`
              )}
              {detailFun(
                "Designation",
                `${dataObj?.designation ? dataObj?.designation : ""}`
              )}
              {detailFun(
                "Location",
                `${dataObj?.location ? dataObj?.location : ""}`
              )}
              {detailFun(
                "Experience",
                `${dataObj?.experience ? dataObj?.experience : ""}`
              )}
              {detailFun(
                "Year of Commencement",
                `${
                  dataObj?.yearOfCommencement ? dataObj?.yearOfCommencement : ""
                }`
              )}
              {detailFun(
                "Year of Completion",
                `${dataObj?.yearOfCompletion ? dataObj?.yearOfCompletion : ""}`
              )}
              {/* <Button type="primary" onClick={() => {}}>
                Unassign Inqury
              </Button> */}
            </div>
          </div>
        );
      }):(
        <div style={{ textAlign: "center" }}>
          No experiences availble.
        </div>
      )}
    </div>
  );
};

export default Experience;
