import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { allActions } from "../../Redux/myActions";
const { Option } = Select;

const AddSubscription = ({
  isAddSubscriptionModalVisible,
  setIsAddSubscriptionModalVisible,
  fetchActiveSubscriptions,
  fetchAllSubscriptions,
}) => {
  const [subscriptionDuration, setSubscriptionDuration] = useState("CUSTOM");
  const [billingStartFrom, setBillingStartFrom] = useState("NOW");
  const [isFree, setIsFree] = useState(false);
  const actions = useDispatch();
  const [form] = Form.useForm();
  const addSubscriptionSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
      isFree: value.packagePrice === 0 ? true : value.isFree,
      packagePrice: value.isFree ? 0 : value.packagePrice,
    };
    actions(
      allActions(
        { ...formData },
        {
          method: "post",
          endPoint: `/package/add`,
          attempt: "ADD_SUBSCRIPTION_REQUEST",
          success: "ADD_SUBSCRIPTION_REQUEST_SUCCESS",
          failure: "ADD_SUBSCRIPTION_REQUEST_FAILURE",
          successInternalState: (data) => {
            console.log("institute add response data", data);
            fetchActiveSubscriptions();
            fetchAllSubscriptions();
          },
          saveBearerToken: true,
        }
      )
    );
    form.resetFields();
    setIsAddSubscriptionModalVisible(false);
  };
  return (
    <Drawer
      title="Add Subscription"
      open={isAddSubscriptionModalVisible}
      onClose={() => {
        setIsAddSubscriptionModalVisible(false);
      }}
      footer={null}
      width={1160}
    >
      <Form
        layout="vertical"
        onFinish={addSubscriptionSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["paymentWhen"],
            value: billingStartFrom,
          },
        ]}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Package Name"
            name={"packageName"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter package name!",
              },
            ]}
          >
            <Input placeholder="Package Name" />
          </Form.Item>
          {isFree === false && (
            <Form.Item
              label="Package Price"
              name={"packagePrice"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter package price!",
                },
              ]}
            >
              <Input type="number" placeholder="Package Price" />
            </Form.Item>
          )}
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Package Duration"
            name={"packageDuration"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter package duration",
              },
            ]}
          >
            <Select placeholder="Package Duration">
              <Option value="10_DAYS">10 Days</Option>
              <Option value="15_DAYS">15 Days</Option>
              <Option value="1_MONTH">1 Month</Option>
              <Option value="3_MONTHS">3 Months</Option>
              <Option value="6_MONTHS">6 Months</Option>
              <Option value="1_YEAR">1 Year</Option>
              <Option value="2_YEAR">2 Years</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Discount (%)"
            name={"discount"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter discount",
              },
            ]}
          >
            <Input type="number" placeholder="Discount" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label={`Subscription Duration ${
              subscriptionDuration === "YEARLY"
                ? "(Plan Duration: 365 days)"
                : subscriptionDuration === "MONTHLY"
                ? "(Plan Duration: 30 Days)"
                : ""
            }`}
            name={"subscriptionDuration"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter subscription duration!",
              },
            ]}
          >
            <Select
              onChange={(e) => {
                setSubscriptionDuration(e.target.value);
              }}
            >
              <Option value="CUSTOM">Custom</Option>
              <Option value="MONTHLY">Monthly</Option>
              <Option value="YEARLY">Yearly</Option>
            </Select>
          </Form.Item>

          {subscriptionDuration === "CUSTOM" && (
            <Form.Item
              label="Plan Duration (in days)"
              name={"planDuration"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter plan duration!",
                },
              ]}
            >
              <Input type="number" placeholder="Plan Duration" />
            </Form.Item>
          )}
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="User Count"
            name={"userCount"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter user count!",
              },
            ]}
          >
            <Input type="number" placeholder="User Count" />
          </Form.Item>
          <Form.Item
            label="Device Count"
            name={"deviceCount"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter deviceCount!",
              },
            ]}
          >
            <Input type="number" placeholder="Device Count" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Lead Forms"
            name={"leadForms"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter lead forms!",
              },
            ]}
          >
            <Input type="number" placeholder="Lead Forms" />
          </Form.Item>
          <Form.Item
            label="Lead Generation"
            name={"leadGeneration"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter lead generation!",
              },
            ]}
          >
            <Input placeholder="Lead Generation" />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Storage In GB"
            name={"storageInGb"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter Storage In GB!",
              },
            ]}
          >
            <Input placeholder="Storage In GB" />
          </Form.Item>
          <Form.Item
            name={"isFree"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox
              onChange={(e) => {
                setIsFree(e.target.checked);
              }}
            >
              Is Free?
            </Checkbox>
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Price Per User"
            name={"pricePerUser"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter Price Per User!",
              },
            ]}
          >
            <Input placeholder="Price Per User" />
          </Form.Item>
          <Form.Item
            label="Addon Price Per GB"
            name={"addOnPricePerGB"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter Addon Price Per GB!",
              },
            ]}
          >
            <Input type="number" placeholder="Addon Price Per GB" />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Billing Start From"
            name={"paymentWhen"}
            style={{ width: "100%" }}
          >
            <Radio.Group
              onChange={(e) => {
                setBillingStartFrom(e.target.value);
              }}
            >
              <Radio value={"NOW"}>Now</Radio>
              <Radio value={"SCHEDULED"}>Scheduled</Radio>
            </Radio.Group>
          </Form.Item>
          {billingStartFrom === "SCHEDULED" && (
            <Form.Item
              label="Scheduled Days"
              name={"scheduledDays"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter scheduled days!",
                },
              ]}
            >
              <Input type="number" placeholder="Scheduled Days" />
            </Form.Item>
          )}
        </div>
        <Form.Item
          label="Description"
          name={"description"}
          //   rules={[
          //     {
          //       required: true,
          //       message: "Please enter description!",
          //     },
          //   ]}
        >
          <TextArea placeholder="Write something about package." />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Subscription
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddSubscription;
