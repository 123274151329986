import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  allStudentEnquiry: {
    data: [],
    totalPage: 0,
    totalData: 0,
    pageNumber: 0,
    hasNext: 0,
  },
  studentEnquiryOfStatus: {
    data: [],
    totalPage: 0,
    totalData: 0,
    pageNumber: 0,
    hasNext: 0,
  },
  studentEnquiryById: {},
  studentsEnquiriesByStudentId: {
    data: [],
    totalPage: 0,
    totalData: 0,
    pageNumber: 0,
    hasNext: 0,
  },
};

const StudentInquiryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_ALL_STUDENT_ENQUIRY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ALL_STUDENT_ENQUIRY_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        allStudentEnquiry: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ALL_STUDENT_ENQUIRY_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ENQUIRY_OF_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ENQUIRY_OF_STATUS_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        studentEnquiryOfStatus: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ENQUIRY_OF_STATUS_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_STUDENT_ENQUIRY_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_STUDENT_ENQUIRY_BY_ID_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        studentEnquiryById: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_STUDENT_ENQUIRY_BY_ID_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ALL_STUDENT_ENQUIRY_STUDENT_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ALL_STUDENT_ENQUIRY_STUDENT_ID_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        studentsEnquiriesByStudentId: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ALL_STUDENT_ENQUIRY_STUDENT_ID_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to fetch Student Enquiries.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ASSIGN_ENQUIRY_TO_CONSULTANCY": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ASSIGN_ENQUIRY_TO_CONSULTANCY_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Enquiry assigned.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ASSIGN_ENQUIRY_TO_CONSULTANCY_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to assign enquiry.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "CANCEL_STUDENT_ENQUIRY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "CANCEL_STUDENT_ENQUIRY_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! enquiry cancelled.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "CANCEL_STUDENT_ENQUIRY_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to cancel enquiry.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE__REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE__REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! StudentInquiry deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE__REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to delete StudentInquiry.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE__STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE__STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! StudentInquiry status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE__STATUS_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Error! Unable to change StudentInquiry status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default StudentInquiryReducer;
