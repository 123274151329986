import { CheckOutlined, CheckSquareOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Divider, Form, Input, Radio } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SubscrptionAddons from "./SubscriptionAddons";
import { allActions } from "../../../Redux/myActions";
import SiderDemo from "../../../components/Siderdemo";

const SubscriptionList = () => {
  const store = useSelector((state) => {
    return {
      profileState: state.LoginReducer,
      loginState: state.ProfileReducer,
      subscritpionState: state.SubscriptionReducer,
    };
  });
  const [planType, setPlanType] = useState("CUSTOM");
  useEffect(() => {
    if (
      store.subscritpionState.subscriptionResponse?.paymentStatus ===
        "unpaid" &&
      store.subscritpionState.subscriptionResponse?.redirectURL
    ) {
      console.log(
        "subscriptionResponse",
        store.subscritpionState?.subscriptionResponse
      );

      window.location.replace(
        store.subscritpionState.subscriptionResponse?.redirectURL
      );
    }
  }, [store.subscritpionState.subscriptionResponse?.redirectURL]);
  const fetchActiveSubscriptions = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/package/get-all-active?page=1&pageSize=100`,
          attempt: "FETCH_ACTIVE_SUBSCRIPTIONS_REQUEST",
          success: "FETCH_ACTIVE_SUBSCRIPTIONS_REQUEST_SUCCESS",
          failure: "FETCH_ACTIVE_SUBSCRIPTIONS_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  useEffect(() => {
    fetchActiveSubscriptions();
  }, []);
  const [
    isSubscriptionAddonsModalVisible,
    setIsSubscriptionAddonsModalVisible,
  ] = useState(false);
  const actions = useDispatch();
  const [noOfUsers, setNoOfUsers] = useState(1);
  const [addonStorage, setAddonStorage] = useState(1);
  const [subscriptionRecord, setSubscriptionRecord] = useState();
  const [isActive, setIsActive] = useState(true);
  console.log("Subscription list state", store.subscritpionState);
  console.log(
    "subscriptionResponse",
    store.subscritpionState?.subscriptionResponse
  );

  console.log("Subscription subscriptionRecord", subscriptionRecord);
  return (
    <SiderDemo organizationalSettings={true}>
      <div
        style={{
          width: "60%",
          margin: "2rem 0rem 0 2rem",
        }}
      >
        <h3>Choose a Plan:</h3>
        <div
          style={{
            backgroundColor: "white",
            border: "10px",
            border: "2px solid #f0f2f5",
            display: "flex",
            flexDirection: "column",
            padding: "0.5rem",
          }}
        >
          <Radio.Group
            onChange={(e) => {
              setPlanType(e.target.value);
            }}
            defaultValue="CUSTOM"
            style={{ margin: "1rem" }}
          >
            <Radio value={"CUSTOM"}>Custom Plan</Radio>
            <Radio value={"MONTHLY"}>Monthly Plan</Radio>
            <Radio value={"YEARLY"}>Yearly Plan</Radio>
          </Radio.Group>
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              margin: "1rem",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginTop: "3rem",
            }}
          >
            {store.subscritpionState.activeSubscriptions?.data?.map(
              (dataObj) => {
                return (
                  <>
                    {dataObj?.subscriptionDuration === "CUSTOM" &&
                      planType === "CUSTOM" &&
                      dataObj?.isFree === false && (
                        <div
                          style={{
                            width: "19rem",
                            margin: "1rem 0 1rem 0",
                            cursor: "pointer",

                            border:
                              subscriptionRecord?.id === dataObj?.id
                                ? "2px solid blue"
                                : "2px solid #ccc",
                            borderRadius: "0.5rem",
                          }}
                          onClick={() => {
                            setSubscriptionRecord(dataObj);
                            setIsActive(true);
                          }}
                        >
                          <Badge.Ribbon text={dataObj.packageName} color="cyan">
                            <Card
                              bordered={false}
                              key={dataObj.id}
                              title={dataObj.packageName}
                            >
                              <div>
                                <span className="subscriptionPrice">
                                  $ {dataObj.packagePrice}
                                </span>
                                <span style={{ color: "black" }}>
                                  {" "}
                                  Per Month
                                </span>
                              </div>
                              <div style={{ paddingLeft: "1rem" }}>
                                <CheckOutlined /> {dataObj.userCount ?? 1} users
                                included.
                              </div>
                              <div style={{ paddingLeft: "1rem" }}>
                                <CheckOutlined /> ${dataObj.pricePerUser ?? 0}{" "}
                                per extra user.
                              </div>

                              {planType === "YEARLY" && (
                                <div style={{ margin: "0.5rem 0 0 0" }}>
                                  Billed{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    $ {+dataObj.packagePrice * 12}
                                  </span>{" "}
                                  Per Annually
                                </div>
                              )}
                            </Card>
                          </Badge.Ribbon>
                        </div>
                      )}
                    {dataObj?.subscriptionDuration === "MONTHLY" &&
                      planType === "MONTHLY" &&
                      dataObj?.isFree === false && (
                        <div
                          style={{
                            width: "19rem",
                            margin: "1rem 0 1rem 0",
                            cursor: "pointer",

                            border:
                              subscriptionRecord?.id === dataObj?.id
                                ? "2px solid blue"
                                : "2px solid #ccc",
                            borderRadius: "0.5rem",
                          }}
                          onClick={() => {
                            setSubscriptionRecord(dataObj);
                            setIsActive(true);
                          }}
                        >
                          <Badge.Ribbon text={dataObj.packageName} color="cyan">
                            <Card
                              bordered={false}
                              key={dataObj.id}
                              title={dataObj.packageName}
                            >
                              <div>
                                <span className="subscriptionPrice">
                                  $ {dataObj.packagePrice}
                                </span>
                                <span style={{ color: "black" }}>
                                  {" "}
                                  Per Month
                                </span>
                              </div>
                              <div style={{ paddingLeft: "1rem" }}>
                                <CheckOutlined /> {dataObj.userCount ?? 1} users
                                included.
                              </div>
                              <div style={{ paddingLeft: "1rem" }}>
                                <CheckOutlined /> ${dataObj.pricePerUser ?? 0}{" "}
                                per extra user.
                              </div>

                              {planType === "YEARLY" && (
                                <div style={{ margin: "0.5rem 0 0 0" }}>
                                  Billed{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    $ {+dataObj.packagePrice * 12}
                                  </span>{" "}
                                  Per Annually
                                </div>
                              )}
                            </Card>
                          </Badge.Ribbon>
                        </div>
                      )}
                    {dataObj?.subscriptionDuration === "YEARLY" &&
                      planType === "YEARLY" &&
                      dataObj?.isFree === false && (
                        <div
                          style={{
                            width: "19rem",
                            margin: "1rem 0 1rem 0",
                            cursor: "pointer",

                            border:
                              subscriptionRecord?.id === dataObj?.id
                                ? "2px solid blue"
                                : "2px solid #ccc",
                            borderRadius: "0.5rem",
                          }}
                          onClick={() => {
                            setSubscriptionRecord(dataObj);
                            setIsActive(true);
                          }}
                        >
                          <Badge.Ribbon text={dataObj.packageName} color="cyan">
                            <Card
                              bordered={false}
                              key={dataObj.id}
                              title={dataObj.packageName}
                            >
                              <div>
                                <span className="subscriptionPrice">
                                  $ {dataObj.packagePrice}
                                </span>
                                <span style={{ color: "black" }}>
                                  {" "}
                                  Per Month
                                </span>
                              </div>
                              <div style={{ paddingLeft: "1rem" }}>
                                -{dataObj.userCount ?? 1} users included.
                              </div>
                              <div style={{ paddingLeft: "1rem" }}>
                                -${dataObj.pricePerUser ?? 0} per extra user.
                              </div>

                              {planType === "YEARLY" && (
                                <div style={{ margin: "0.5rem 0 0 0" }}>
                                  Billed{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    $ {+dataObj.packagePrice * 12}
                                  </span>{" "}
                                  Per Annually
                                </div>
                              )}
                            </Card>
                          </Badge.Ribbon>
                        </div>
                      )}
                  </>
                );
              }
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3>Users and Addons</h3>
            <Form.Item
              label="Number Of Users"
              // name={"noOfUser"}
              wrapperCol={{
                span: 4,
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Input
                type="number"
                value={noOfUsers}
                onChange={(e) => {
                  setNoOfUsers(e.target.value);
                }}
              />
            </Form.Item>
            {/* <Form.Item
              label="Storage"
              name={"addOnPricePerGB"}
              wrapperCol={{
                span: 4,
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Input
                type="number"
                value={addonStorage}
                onChange={(e) => {
                  setAddonStorage(e.target.value);
                }}
                style={{ border: "1px solid red" }}
              />
            </Form.Item> */}
            <div>
              <Form.Item
                name="review"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select a package",
                //   },
                // ]}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    // dispatch({
                    //   type: "PURCHASE_SUBSCRIPTION_REQUEST",
                    //   payload: {
                    //     subscriptionId: subscriptionRecord?.id,
                    //     confirmationText: "",
                    //   },
                    // });
                    setIsSubscriptionAddonsModalVisible(true);
                  }}
                  size="large"
                  style={{ borderRadius: "0.5rem" }}
                  name="review"
                  disabled={subscriptionRecord === undefined}
                >
                  Review
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
        {isSubscriptionAddonsModalVisible && (
          <SubscrptionAddons
            subscriptionRecord={subscriptionRecord}
            noOfUsers={noOfUsers}
            isSubscriptionAddonsModalVisible={isSubscriptionAddonsModalVisible}
            setIsSubscriptionAddonsModalVisible={
              setIsSubscriptionAddonsModalVisible
            }
          />
        )}
      </div>
    </SiderDemo>
  );
};

export default SubscriptionList;

{
  /* <p>
                      <span>
                        <AiOutlineCheck color="green" />
                      </span>{" "}
                      Package Price: <span>${dataObj.packagePrice}</span>
                    </p>
                    <Divider
                      orientationMargin="0"
                      style={{ margin: "0.5rem" }}
                    />
                    <p>
                      <AiOutlineCheck color="green" />
                      Discount: <span>{dataObj.discount}%</span>
                    </p>
                    <Divider
                      orientationMargin="0"
                      style={{ margin: "0.5rem" }}
                    />
                    <p>
                      <AiOutlineCheck color="green" />
                      Lead Form: <span>{dataObj.leadForms}</span>
                    </p>
                    <Divider
                      orientationMargin="0"
                      style={{ margin: "0.5rem" }}
                    />
                    <p>
                      <AiOutlineCheck color="green" />
                      Lead Generation: <span>{dataObj.leadGeneration}</span>
                    </p>
                    <Divider
                      orientationMargin="0"
                      style={{ margin: "0.5rem" }}
                    />
                    <p>
                      <AiOutlineCheck color="green" />
                      Storage: <span>{dataObj.storage}</span>
                    </p> */
}
