import { message } from "antd";
import { removeCookie } from "../../Helpers/FrontendHelper";

const INIT_STATE = {
  isLoading: false,
  isAuthenticated: false,
};

const LoginReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADMIN_AUTHENTICATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADMIN_AUTHENTICATION_REQUEST_SUCCESS": {
      console.log("from reducer", action.payload);
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
      };
    }
    case "ADMIN_AUTHENTICATION_REQUEST_FAILURE": {
      console.log("from reducer", action.payload);
      if (action.payload?.data?.message) {
        message.error(action.payload?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error! Unable to login.");
      }
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
      };
    }

    case "LOGOUT_CONSULTANCY_ADMIN_REQUEST": {
      removeCookie("accessToken");
      // removeLocalStorage("menuList");
      // removeLocalStorage("permissionList");
      // removeLocalStorage("branchName");
      // removeLocalStorage("branchId");

      window.location.href = "/";
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default LoginReducer;
