import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Image,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../components/Siderdemo";
import { allActions } from "../../Redux/myActions";
import {
  AiOutlineCopy,
  AiOutlineEye,
  AiOutlineMail,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { FiFlag, FiUsers } from "react-icons/fi";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import { SiGoogleclassroom } from "react-icons/si";
import { BsGenderAmbiguous } from "react-icons/bs";
import {
  twoTagResponses,
  twoTagResponsesActive,
} from "../../Helpers/HelperFunction";
import { BiBlock } from "react-icons/bi";
import StudentDetails from "./StudentDetails/StudentDetails";
const columns = [
  {
    title: "Id",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Image",
    dataIndex: "image",
    align: "center",
  },
  {
    title: "Name",
    dataIndex: "fullName",
    align: "center",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    align: "center",
  },
  {
    title: "Gender/Marital Status",
    dataIndex: "gender",
    align: "center",
  },
  {
    title: "Date of Birth",
    dataIndex: "dob",
    align: "center",
  },
  {
    title: "Address",
    dataIndex: "address",
    align: "center",
  },
  {
    title: "Is Email Verified?",
    dataIndex: "isEmailVerified",
    align: "center",
  },
  {
    title: "Is Phone Verified",
    dataIndex: "isPhoneVerified",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const Student = () => {
  const store = useSelector((state) => {
    return {
      StudentState: state.StudentReducer,
    };
  });
  const [isAddStudentModalVisible, setIsAddStudentModalVisible] =
    useState(false);
  const [isUpdateStudentModalVisible, setIsUpdateStudentModalVisible] =
    useState(false);
  const [isStudentDetailsModalVisible, setIsStudentDetailsModalVisible] =
    useState(false);
  const [isAssignConsultancyModalVisible, setIsAssignConsultancyModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [StudentType, setStudentType] = useState("ALL");
  const action = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };

  const fetchAllStudent = () => {
    action(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/get-all-student-data?page=${page}&pageSize=${pageSize}`,
          attempt: "FETCH_ALL_STUDENT_REQUEST",
          success: "FETCH_ALL_STUDENT_REQUEST_SUCCESS",
          failure: "FETCH_ALL_STUDENT_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  const fetchActiveStudent = () => {
    action(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/get-student-data-by-isActive?page=${page}&pageSize=${pageSize}`,
          attempt: "FETCH_ACTIVE_STUDENT_REQUEST",
          success: "FETCH_ACTIVE_STUDENT_REQUEST_SUCCESS",
          failure: "FETCH_ACTIVE_STUDENT_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };

  useEffect(() => {
    if (StudentType === "ALL") {
      fetchAllStudent();
    } else {
      fetchActiveStudent();
    }
  }, [StudentType]);
  let data;
  if (StudentType === "ALL") {
    data = store.StudentState?.students?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: dataObj.id,
        image: dataObj.studentImage ? (
          <Image
            src={dataObj.studentImage}
            style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
          />
        ) : (
          <Avatar
            style={{
              backgroundColor: `#${Math.floor(
                Math.random() * 16777215
              ).toString(16)}`,
              verticalAlign: "middle",
            }}
            size="large"
          >
            {dataObj?.fullName?.charAt(0).toUpperCase()}
          </Avatar>
        ),
        fullName: dataObj?.fullName ? (
          <Tooltip title="View Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsStudentDetailsModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                {dataObj?.fullName}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        contact: (
          <div className="flexColumnWithoutStyle">
            {dataObj?.email !== "" &&
            dataObj?.email !== null &&
            dataObj?.email !== undefined ? (
              <Tooltip title="Send Email">
                <a>
                  <div className="bordered">
                    <div
                      className="flexRowWithoutStyle"
                      style={{ gap: "2px", color: "000", color: "000" }}
                    >
                      <AiOutlineMail />
                      <a href={`mailto:${dataObj?.email}`}>
                        {dataObj?.email ?? "N/A"}
                      </a>
                    </div>
                  </div>
                </a>
              </Tooltip>
            ) : null}
            {dataObj?.phoneNumber !== "" &&
            dataObj?.phoneNumber !== null &&
            dataObj?.phoneNumber !== undefined ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <CiMobile3 />
                <a href={`tel:${dataObj?.phoneNumber}`}>
                  {dataObj?.phoneNumber ?? "N/A"}
                </a>
              </div>
            ) : null}
          </div>
        ),
        gender: (
          <div className="flexColumnWithoutStyle">
            {dataObj?.gender !== "" &&
            dataObj?.gender !== null &&
            dataObj?.gender !== undefined ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000", color: "000" }}
              >
                <BsGenderAmbiguous /> <span>{dataObj?.gender ?? "N/A"}</span>
              </div>
            ) : null}
            {dataObj?.maritalStatus !== "" &&
            dataObj?.maritalStatus !== null &&
            dataObj?.maritalStatus !== undefined ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <FiUsers /> <span>{dataObj?.maritalStatus ?? "N/A"}</span>
              </div>
            ) : null}
          </div>
        ),
        dob: dataObj?.dob ?? "N/A",
        address: dataObj?.address ?? "N/A",
        isEmailVerified: dataObj?.isEmailVerified
          ? twoTagResponses(dataObj?.isEmailVerified, true, false)
          : "N/A",
        isPhoneVerified: dataObj?.isPhoneVerified
          ? twoTagResponses(dataObj?.isPhoneVerified, true, false)
          : "N/A",
        status: twoTagResponsesActive(dataObj?.isActive, true, false),

        actions: (
          <Space size="middle">
            <Tooltip title="View Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsStudentDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {dataObj?.isActive && (
              <Tooltip title="Deactivate Student">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = dataObj.isActive
                        ? "inactive"
                        : "active";
                      action(
                        allActions(
                          {},
                          {
                            method: "put",
                            endPoint: `/toggle-active-status/${dataObj.id}`,
                            attempt: "TOGGLE_STUDENT_STATUS_REQUEST",
                            success: "TOGGLE_STUDENT_STATUS_REQUEST_SUCCESS",
                            failure: "TOGGLE_STUDENT_STATUS_REQUEST_FAILURE",
                            //   navigateTo: null,
                            successInternalState: (data) => {
                              fetchAllStudent();
                            },
                            saveBearerToken: true,
                          }
                        )
                      );
                      // dispatch({
                      //   type: "TOGGLE_STUDENT_STATUS_REQUEST",
                      //   payload: { id: dataObj.id, actionStatus },
                      // });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            )}
            {!dataObj?.isActive && (
              <Tooltip title="Activate Student">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = dataObj.isActive
                        ? "inactive"
                        : "active";
                      action(
                        allActions(
                          {},
                          {
                            method: "put",
                            endPoint: `/toggle-active-status/${dataObj.id}`,
                            attempt: "TOGGLE_STUDENT_STATUS_REQUEST",
                            success: "TOGGLE_STUDENT_STATUS_REQUEST_SUCCESS",
                            failure: "TOGGLE_STUDENT_STATUS_REQUEST_FAILURE",
                            //   navigateTo: null,
                            successInternalState: (data) => {
                              fetchAllStudent();
                            },
                            saveBearerToken: true,
                          }
                        )
                      );
                      // dispatch({
                      //   type: "TOGGLE_STUDENT_STATUS_REQUEST",
                      //   payload: { id: dataObj.id, actionStatus },
                      // });
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  } else {
    data = store.StudentState?.activeStudents?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: dataObj.id,
        image: dataObj.studentImage ? (
          <Image
            src={dataObj.studentImage}
            style={{ height: "50px", width: "50px", border: "1px solid #ccc" }}
          />
        ) : (
          <Avatar
            style={{
              backgroundColor: `#${Math.floor(
                Math.random() * 16777215
              ).toString(16)}`,
              verticalAlign: "middle",
            }}
            size="large"
          >
            {dataObj?.fullName?.charAt(0).toUpperCase()}
          </Avatar>
        ),
        fullName: dataObj?.fullName ? (
          <Tooltip title="View Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsStudentDetailsModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                {dataObj?.fullName}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        contact: (
          <div className="flexColumnWithoutStyle">
            {dataObj?.email !== "" &&
            dataObj?.email !== null &&
            dataObj?.email !== undefined ? (
              <Tooltip title="Send Email">
                <a>
                  <div className="bordered">
                    <div
                      className="flexRowWithoutStyle"
                      style={{ gap: "2px", color: "000", color: "000" }}
                    >
                      <AiOutlineMail />
                      <a href={`mailto:${dataObj?.email}`}>
                        {dataObj?.email ?? "N/A"}
                      </a>
                    </div>
                  </div>
                </a>
              </Tooltip>
            ) : null}
            {dataObj?.phoneNumber !== "" &&
            dataObj?.phoneNumber !== null &&
            dataObj?.phoneNumber !== undefined ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <CiMobile3 />
                <a href={`tel:${dataObj?.phoneNumber}`}>
                  {dataObj?.phoneNumber ?? "N/A"}
                </a>
              </div>
            ) : null}
          </div>
        ),
        gender: (
          <div className="flexColumnWithoutStyle">
            {dataObj?.gender !== "" &&
            dataObj?.gender !== null &&
            dataObj?.gender !== undefined ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000", color: "000" }}
              >
                <BsGenderAmbiguous /> <span>{dataObj?.gender ?? "N/A"}</span>
              </div>
            ) : null}
            {dataObj?.maritalStatus !== "" &&
            dataObj?.maritalStatus !== null &&
            dataObj?.maritalStatus !== undefined ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <FiUsers /> <span>{dataObj?.maritalStatus ?? "N/A"}</span>
              </div>
            ) : null}
          </div>
        ),
        dob: dataObj?.dob ?? "N/A",
        address: dataObj?.address ?? "N/A",
        isEmailVerified: dataObj?.isEmailVerified
          ? twoTagResponses(dataObj?.isEmailVerified, true, false)
          : "N/A",
        isPhoneVerified: dataObj?.isPhoneVerified
          ? twoTagResponses(dataObj?.isPhoneVerified, true, false)
          : "N/A",
        status: twoTagResponsesActive(dataObj?.isActive, true, false),

        actions: (
          <Space size="middle">
            <Tooltip title="View Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsStudentDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Deactivate Student">
              <a style={{ color: "red" }}>
                <div
                  className="bordered"
                  onClick={() => {
                    const actionStatus = dataObj.isActive
                      ? "inactive"
                      : "active";
                    action(
                      allActions(
                        {},
                        {
                          method: "put",
                          endPoint: `/toggle-active-status/${dataObj.id}`,
                          attempt: "TOGGLE_STUDENT_STATUS_REQUEST",
                          success: "TOGGLE_STUDENT_STATUS_REQUEST_SUCCESS",
                          failure: "TOGGLE_STUDENT_STATUS_REQUEST_FAILURE",
                          //   navigateTo: null,
                          successInternalState: (data) => {
                            fetchActiveStudent();
                          },
                          saveBearerToken: true,
                        }
                      )
                    );
                    // dispatch({
                    //   type: "TOGGLE_STUDENT_STATUS_REQUEST",
                    //   payload: { id: dataObj.id, actionStatus },
                    // });
                  }}
                >
                  <BiBlock />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Activate Student">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    const actionStatus = dataObj.isActive
                      ? "inactive"
                      : "active";
                    action(
                      allActions(
                        {},
                        {
                          method: "put",
                          endPoint: `/toggle-active-status/${dataObj.id}`,
                          attempt: "TOGGLE_STUDENT_STATUS_REQUEST",
                          success: "TOGGLE_STUDENT_STATUS_REQUEST_SUCCESS",
                          failure: "TOGGLE_STUDENT_STATUS_REQUEST_FAILURE",
                          //   navigateTo: null,
                          successInternalState: (data) => {
                            fetchActiveStudent();
                          },
                          saveBearerToken: true,
                        }
                      )
                    );
                    // dispatch({
                    //   type: "TOGGLE_STUDENT_STATUS_REQUEST",
                    //   payload: { id: dataObj.id, actionStatus },
                    // });
                  }}
                >
                  <AiOutlinePoweroff />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),
      };
    });
  }
  console.log("from Student state", store.StudentState);
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>
                      All Students ({store.StudentState?.students?.totalData})
                    </div>
                  </div>
                ),
                value: "ALL",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>
                      Active Students
                      {store.StudentState?.activeStudents?.totalData !== 0
                        ? ` (${store.StudentState?.activeStudents?.totalData})`
                        : ""}
                    </div>
                  </div>
                ),
                value: "ACTIVE",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setStudentType(value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {store.StudentState?.isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <Table
                bordered
                size="small"
                columns={columns}
                dataSource={data}
                pagination={false}
              />

              {StudentType === "ALL" &&
              store.StudentState?.students?.totalData ? (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={store.StudentState?.students?.totalPage * 10}
                />
              ) : (
                ""
              )}
              {StudentType !== "ALL" &&
              store.StudentState?.activeStudents?.totalData ? (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={store.StudentState?.activeStudents?.totalPage * 10}
                />
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
      {isStudentDetailsModalVisible && (
        <StudentDetails
          record={record}
          isStudentDetailsModalVisible={isStudentDetailsModalVisible}
          setIsStudentDetailsModalVisible={setIsStudentDetailsModalVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Student;
