import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { BiBlock } from "react-icons/bi";

import SiderDemo from "../../../components/Siderdemo";
import { Popconfirm, Segmented, Space, Table, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { AiOutlinePoweroff } from "react-icons/ai";
import AddEditDegree from "./AddEditDegree";
import { allActions } from "../../../Redux/myActions";
import { useSelector } from "react-redux";

const Degrees = () => {
  const actions = useDispatch();
  const InstituteState = useSelector((store) => store?.InstituteReducer);

  const [isDegreeModalVisible, setIsDegreeModalVisible] = useState(false);
  const [isEditDegreeModalVisible, setIsEditDegreeModalVisible] =
    useState(false);
  const [degreeType, setDegreeType] = useState("degree");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [record, setRecord] = useState(null);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "Degree",
      dataIndex: "degreeName",
      align: "center",
    },

    {
      title: "Status",
      dataIndex: "isActive",
      align: "center",
      render: (data) =>
        data === true ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
    },
    {
      title: "Actions",

      align: "center",
      render: (dataObj) => (
        <Space size="middle">
          <Tooltip title="Update degree">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsEditDegreeModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          {/* <Tooltip title="Delete degree"> */}
          <Popconfirm
            placement="topRight"
            title="Are you sure to delete this degree?"
            onConfirm={() => {
              actions(
                allActions(
                  {},
                  {
                    method: "delete",
                    endPoint: `/degree/delete/${dataObj?.id}`,
                    attempt: "DELETE_DEGREE_REQUEST",
                    success: "DELETE_DEGREE_REQUEST_SUCCESS",
                    failure: "DELETE_DEGREE_REQUEST_FAILURE",

                    saveBearerToken: false,
                    successInternalState: () => {
                      fetchAdminDegree();
                    },
                  }
                )
              );
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <a>
              <div className="bordered">
                <DeleteOutlined style={{ color: "red" }} />
              </div>
            </a>{" "}
          </Popconfirm>
          {/* </Tooltip> */}
          {dataObj?.isActive ? (
            <Tooltip title="Deactivate degree">
              <a style={{ color: "red" }}>
                <div
                  className="bordered"
                  // onClick={() => {
                  //   const actionStatus = !dataObj.isActive
                  //     ? "active"
                  //     : "inactive";
                  //   actions({
                  //     type: "TOGGLE_DEGREE_STATUS_REQUEST",
                  //     payload: {
                  //       degreeId: dataObj.id,
                  //       status: actionStatus,
                  //     },
                  //   });
                  // }}
                  onClick={() => {
                    actions(
                      allActions(
                        {},
                        {
                          method: "patch",
                          endPoint: `/degree/change-status/inactive/${dataObj?.id}`,
                          attempt: "TOGGLE_DEGREE_STATUS_REQUEST",
                          success: "TOGGLE_DEGREE_STATUS_REQUEST_SUCCESS",
                          failure: "TOGGLE_DEGREE_STATUS_REQUEST_FAILURE",

                          saveBearerToken: false,
                          successInternalState: () => {
                            fetchAdminDegree();
                          },
                        }
                      )
                    );
                  }}
                >
                  <BiBlock />
                </div>
              </a>
            </Tooltip>
          ) : (
            <Tooltip title="Activate degree">
              <a>
                <div
                  className="bordered"
                  // onClick={() => {
                  //   const actionStatus = !dataObj.isActive
                  //     ? "active"
                  //     : "inactive";
                  //   dispatch({
                  //     type: "TOGGLE_DEGREE_STATUS_REQUEST",
                  //     payload: { degreeId: dataObj.id, status: actionStatus },
                  //   });
                  // }}
                  onClick={() => {
                    actions(
                      allActions(
                        {},
                        {
                          method: "patch",
                          endPoint: `/degree/change-status/active/${dataObj?.id}`,
                          attempt: "TOGGLE_DEGREE_STATUS_REQUEST",
                          success: "TOGGLE_DEGREE_STATUS_REQUEST_SUCCESS",
                          failure: "TOGGLE_DEGREE_STATUS_REQUEST_FAILURE",

                          saveBearerToken: false,
                          successInternalState: () => {
                            fetchAdminDegree();
                          },
                        }
                      )
                    );
                  }}
                >
                  <AiOutlinePoweroff />
                </div>
              </a>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchAdminDegree();
  }, []);

  const fetchAdminDegree = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/degree/get-all`,
          attempt: "FETCH_ADMIN_DEGREE",
          success: "FETCH_ADMIN_DEGREE_SUCCESS",
          failure: "FETCH_ADMIN_DEGREE_FAILURE",

          saveBearerToken: false,
        }
      )
    );
  };

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          <div style={{ minWidth: "20%" }}>
            <button
              className="button"
              onClick={() => {
                setIsDegreeModalVisible(true);
              }}
            >
              <span>Add Degree</span>
            </button>
          </div>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>All Degree</div>
                  </div>
                ),
                value: "degree",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Active Degree</div>
                  </div>
                ),
                value: "activeDegree",
              },
            ]}
            onChange={(value) => {
              value === "degree"
                ? actions(
                    allActions(
                      {},
                      {
                        method: "get",
                        endPoint: `/degree/get-all`,
                        attempt: "FETCH_ADMIN_DEGREE",
                        success: "FETCH_ADMIN_DEGREE_SUCCESS",
                        failure: "FETCH_ADMIN_DEGREE_FAILURE",

                        saveBearerToken: false,
                      }
                    )
                  )
                : actions(
                    allActions(
                      {},
                      {
                        method: "get",
                        endPoint: `/degree/get-all-active`,
                        attempt: "FETCH_ADMIN_DEGREE",
                        success: "FETCH_ADMIN_DEGREE_SUCCESS",
                        failure: "FETCH_ADMIN_DEGREE_FAILURE",

                        saveBearerToken: false,
                      }
                    )
                  );
              // dispatch({
              //   type:
              //     value === "degree"
              //       ? "FETCH_DEGREE_REQUEST"
              //       : "FETCH_ACTIVE_DEGREE_REQUEST",
              //   payload: {
              //     page: 1,
              //     pageSize: 10,
              //     branchId: localStorage.getItem("branchId"),
              //   },
              // });
              setPage(1);
              setPageSize(10);
              setDegreeType(value);
            }}
          />
        </div>
        <Table
          columns={columns}
          dataSource={InstituteState?.adminDegree?.data}
          style={{ marginTop: "1rem" }}
          bordered
          pagination={false}
        />
      </div>

      {isDegreeModalVisible && (
        <AddEditDegree
          record=""
          modalType="Add"
          isDegreeModalVisible={isDegreeModalVisible}
          setIsDegreeModalVisible={setIsDegreeModalVisible}
          fetchAdminDegree={fetchAdminDegree}
        />
      )}

      {isEditDegreeModalVisible && (
        <AddEditDegree
          fetchAdminDegree={fetchAdminDegree}
          record={record}
          modalType="Edit"
          isDegreeModalVisible={isEditDegreeModalVisible}
          setIsDegreeModalVisible={setIsEditDegreeModalVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Degrees;
