import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import { AllEpic } from "./myEpics";
import LoginReducer from "./Reducers/Login";
import InstituteReducer from "./Reducers/InstituteReducer";
import ClientReducer from "./Reducers/ClientReducer";
import CoursesReducer from "./Reducers/CoursesReducer";
import SupportReducer from "./Reducers/SupportReducer";
import SettingsReducer from "./Reducers/SettingsReducer";
import DocumentManagementReducer from "./Reducers/DocumentManagementReducer";
import AnnouncementReducer from "./Reducers/AnnouncementReducer";
import SubscriptionReducer from "./Reducers/SubscriptionReducer";
import DashboardReducer from "./Reducers/DashboardReducers";
import StudentInquiryReducer from "./Reducers/StudentInquiry";
import StudentReducer from "./Reducers/StudentReducer";
import VisaSubclassReducer from "./Reducers/VisaSubclassReducer";
export const rootReducer = combineReducers({
  LoginReducer,
  InstituteReducer,
  ClientReducer,
  CoursesReducer,
  SupportReducer,
  SettingsReducer,
  DocumentManagementReducer,
  AnnouncementReducer,
  SubscriptionReducer,
  DashboardReducer,
  StudentInquiryReducer,
  StudentReducer,
  VisaSubclassReducer,
});

export const rootEpic = combineEpics(AllEpic);
