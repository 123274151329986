import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { allActions } from "../../Redux/myActions";
import { date } from "../../Helpers/HelperFunction";
const { Option } = Select;
const { TextArea } = Input;

const UpdateAnnouncement = ({
  record,
  isUpdateAnnouncementModalVisible,
  setIsUpdateAnnouncementModalVisible,
  fetchAllAnnouncements,
  fetchActiveAnnouncements,
}) => {
  const actions = useDispatch();
  const [form] = Form.useForm();
  const announcementSubmitHandler = (value) => {
    console.log("from ", value);
    const formData = {
      ...value,
      announcementStartDate: value.announcementStartDate
        ? date(value.announcementStartDate)
        : "",
      announcementEndDate: value.announcementEndDate
        ? date(value.announcementEndDate)
        : "",
    };
    actions(
      allActions(
        { ...formData },
        {
          method: "put",
          endPoint: `/announcement/update/${record.id}`,
          attempt: "UPDATE_ANNOUNCEMENT_REQUEST",
          success: "UPDATE_ANNOUNCEMENT_REQUEST_SUCCESS",
          failure: "UPDATE_ANNOUNCEMENT_REQUEST_FAILURE",
          //   navigateTo: null,
          successInternalState: (data) => {
            fetchAllAnnouncements();
            fetchActiveAnnouncements();
          },
          saveBearerToken: true,
        }
      )
    );
    // dispatch({ type: "UPDATE_ANNOUNCEMENT_REQUEST", payload: formData });
    // form.resetFields();
    setIsUpdateAnnouncementModalVisible(false);
  };
  return (
    <Modal
      title="Update Announcement"
      open={isUpdateAnnouncementModalVisible}
      onCancel={() => {
        setIsUpdateAnnouncementModalVisible(false);
      }}
      footer={null}
      maskClosable={false}
    >
      <Form
        layout="vertical"
        onFinish={announcementSubmitHandler}
        colon={true}
        form={form}
        fields={[
          {
            name: ["announcementType"],
            value: record.announcementType,
          },
          {
            name: ["announcementText"],
            value: record.announcementText,
          },
          {
            name: ["announcementStartDate"],
            value: record.announcementStartDate
              ? moment(record.announcementStartDate).local()
              : "",
          },
          {
            name: ["announcementEndDate"],
            value: record.announcementEndDate
              ? moment(record.announcementEndDate).local()
              : "",
          },
        ]}
      >
        <Form.Item
          label="Announcement Type"
          name={"announcementType"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter announcement type!",
            },
          ]}
        >
          <Select>
            <Option value="INFORMATIVE">Informative</Option>
            <Option value="ALERT">Alert</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Announcement Text"
          name={"announcementText"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter announcement text!",
            },
          ]}
        >
          <TextArea placeholder="Write about announcement" />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Announcement Start Date"
            name={"announcementStartDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter announcement start date!",
              },
            ]}
          >
            <DatePicker
              disabledDate={(cd) => {
                const d = new Date();
                return cd < moment().subtract(1, "days");
              }}
            />
          </Form.Item>

          <Form.Item
            label="Announcement End Date"
            name={"announcementEndDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter announcement end date",
              },
            ]}
          >
            <DatePicker
              disabledDate={(cd) => {
                const d = new Date();
                return cd < moment().subtract(1, "days");
              }}
            />
          </Form.Item>
        </div>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Announcement
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateAnnouncement;
