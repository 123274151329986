import {
  Badge,
  Button,
  Card,
  Divider,
  Drawer,
  Image,
  Modal,
  Pagination,
  Popconfirm,
  Space,
  Table,
  Tag,
  Timeline,
  Tooltip,
} from "antd";
import React, { useEffect } from "react";
import {
  AiOutlineCloseCircle,
  AiOutlineEye,
  AiOutlineMail,
  AiOutlinePhone,
} from "react-icons/ai";
import client from "./../../../Assets/client.png";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { BsChatLeftText, BsReply } from "react-icons/bs";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  formattedDateTime,
  threeTagResponses,
  threeTagResponsesTransaction,
  twoTagResponses,
  twoTagResponsesWithStatuses,
} from "../../../Helpers/HelperFunction";
import { MdOutlineAssignmentTurnedIn, MdOutlinePending } from "react-icons/md";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { allActions } from "../../../Redux/myActions";
import TableSkeleton from "../../../Helpers/TableSkeleton/tableSkelaton";
import StudentAcademicQualifications from "../../StudentEnquiry/StudentAcademicQualifications/StudentAcademicQualifications";
import AcademicQualifications from "./AcademicQualifications/AcademicQualifications";
import Experience from "./Experience/Experience";
import Enquiries from "./Enquiries/Enquiries";
import StudentDocuments from "./StudentDocuments/StudentDocuments";
const { confirm } = Modal;

const StudentDetails = ({
  record,
  isStudentDetailsModalVisible,
  setIsStudentDetailsModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      studentState: state.StudentReducer,
      studentEnquiryState: state.StudentInquiryReducer,
    };
  });

  const [current, setCurrent] = useState("StudentDetails");
  const [isAssignConsultancyModalVisible, setIsAssignConsultancyModalVisible] =
    useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [supportRecord, setSupportRecord] = useState({});

  const actions = useDispatch();
  const fetchStudentByID = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/get-student-data-by-id/${record?.id}`,
          attempt: "FETCH_STUDENT_BY_ID_REQUEST",
          success: "FETCH_STUDENT_BY_ID_REQUEST_SUCCESS",
          failure: "FETCH_STUDENT_BY_ID_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  const fetchAllStudent = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/get-all-student-data?page=${page}&pageSize=${pageSize}`,
          attempt: "FETCH_ALL_STUDENT_REQUEST",
          success: "FETCH_ALL_STUDENT_REQUEST_SUCCESS",
          failure: "FETCH_ALL_STUDENT_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  const fetchActiveStudent = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/get-student-data-by-isActive?page=${page}&pageSize=${pageSize}`,
          attempt: "FETCH_ACTIVE_STUDENT_REQUEST",
          success: "FETCH_ACTIVE_STUDENT_REQUEST_SUCCESS",
          failure: "FETCH_ACTIVE_STUDENT_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  const fetchStudentEnquiryById = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/get-all-enquiry/${record?.id}?page=${page}&pageSize=${pageSize}`,
          attempt: "FETCH_ALL_STUDENT_ENQUIRY_STUDENT_ID_REQUEST",
          success: "FETCH_ALL_STUDENT_ENQUIRY_STUDENT_ID_REQUEST_SUCCESS",
          failure: "FETCH_ALL_STUDENT_ENQUIRY_STUDENT_ID_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };

  const onShowSizeChange = (current, pageSize) => {
    console.log("page changed", current, pageSize);
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  useEffect(() => {
    fetchStudentByID();
    fetchStudentEnquiryById();
  }, []);

  const StudentDetailsTabList = [
    {
      key: "StudentDetails",
      tab: "Student Details",
    },
    {
      key: "enquiries",
      tab: (
        <Badge
          count={
            store.studentEnquiryState?.studentsEnquiriesByStudentId?.totalData
          }
        >
          Enquiries
        </Badge>
      ),
    },
    {
      key: "englishQualifications",
      tab: "English Qualifications",
    },
    {
      key: "academicQualifications",
      tab: "Academic Qualifications",
    },
    {
      key: "experience",
      tab: "Experience",
    },
    {
      key: "documents",
      tab: "Documents",
    },
  ];

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  const navigate = useNavigate();

  console.log("supportRecord from lead details", store?.studentState);
  return (
    <Drawer
      title="Student Details"
      open={isStudentDetailsModalVisible}
      onClose={() => {
        setIsStudentDetailsModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card
        loading={store?.studentState?.isLoading}
        style={{ borderRadius: "0.3rem" }}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{
            gap: "1rem",
            alignItems: "stretch",
          }}
        >
          {store?.studentState?.studentById?.studentData?.studentImage ? (
            <Image
              src={store?.studentState?.studentById?.studentData?.studentImage}
              className={"clientImage"}
            />
          ) : (
            <Image src={client} className={"clientImage"} />
          )}
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              position: "relative",
              width: "100%",
              gap: "0.5rem",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {`${
                store?.studentState?.studentById?.studentData?.fullName ?? ""
              }`}{" "}
              {`(Student Id: ${
                store?.studentState?.studentById?.studentData?.id ?? ""
              })`}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              {store?.studentState?.studentById?.studentData?.phoneNumber && (
                <span className="flexRowWithGap">
                  <AiOutlinePhone
                    style={{
                      fontSize: "20",
                    }}
                  />{" "}
                  {store?.studentState?.studentById?.studentData?.phoneNumber
                    ? store?.studentState?.studentById?.studentData?.phoneNumber
                    : "N/A"}
                </span>
              )}
              {store?.studentState?.studentById?.studentData?.address && (
                <span className="flexRowWithGap">
                  <GoLocation
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {store?.studentState?.studentById?.studentData?.address
                    ? store?.studentState?.studentById?.studentData?.address
                    : "N/A"}
                </span>
              )}
              {store?.studentState?.studentById?.studentData?.email && (
                <span className="flexRowWithGap">
                  <AiOutlineMail
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {store?.studentState?.studentById?.studentData?.email
                    ? store?.studentState?.studentById?.studentData?.email
                    : "N/A"}
                </span>
              )}
            </div>
            <div>
              {/* <div>
                    <span className="profileTitle">Campaign:</span>
                    <span>
                      {store.leadState.leadById?.customerResponse?.campaign?.name ??
                        "N/A"}
                    </span>
                  </div> */}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.5rem",
                // justifySelf:""
                position: "absolute",
                alignSelf: "flex-start",
                flexWrap: "wrap",
                bottom: 0,
              }}
            >
              {/*  <button
                      className="emailAndMessage"
                      onClick={() => {
                        setIsSendComposedEmailModalVisible(true);
                      }}
                    >
                      <AiOutlineMail />
                      <span>Email</span>
                    </button> */}
              {/* <Button
                type="primary"
                onClick={() => {
                  setIsAssignConsultancyModalVisible(true);
                }}
              >
                Assign Consultancy
              </Button> */}
            </div>
            {store?.studentState?.studentById?.status && (
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
              >
                <Tag color="#108ee9">
                  {store?.studentState?.studentById?.status}
                </Tag>
              </div>
            )}
          </div>
        </div>
        <Card
          bordered={false}
          tabList={StudentDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "StudentDetails" && (
            <>
              <div
                style={{
                  padding: "0.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "row",
                  }}
                >
                  <Card
                    className="clientCard"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {detailFun(
                      "Date of Birth",
                      ` ${
                        store?.studentState?.studentById?.studentData?.dob ??
                        "N/A"
                      }`
                    )}
                    {detailFun(
                      "Marital Status",
                      ` ${
                        store?.studentState?.studentById?.studentData
                          ?.maritalStatus ?? "N/A"
                      }`
                    )}
                    {detailFun(
                      "Gender",
                      `${
                        store?.studentState?.studentById?.studentData?.gender ??
                        ""
                      }`
                    )}

                    {detailFun(
                      "Calling Code",
                      `${
                        store?.studentState?.studentById?.studentData
                          ?.callingCode ?? "N/A"
                      }`
                    )}
                    {detailFun(
                      "Passport Number",
                      `${
                        store?.studentState?.studentById?.studentData
                          ?.passportNumber ?? "N/A"
                      }`
                    )}
                    {detailFun(
                      "Referral Code",
                      `${
                        store?.studentState?.studentById?.studentData
                          ?.referralCode ?? "N/A"
                      }`
                    )}
                    {detailFun(
                      "Referral Points",
                      `${
                        store?.studentState?.studentById?.studentData
                          ?.referralPoints ?? "N/A"
                      }`
                    )}

                    {store?.studentState?.studentById?.studentData
                      ?.isEmailVerified &&
                      detailFun(
                        "Is Email Verified?:",

                        twoTagResponses(
                          store?.studentState?.studentById?.studentData
                            ?.isEmailVerified,
                          true,
                          false
                        ) ?? "N/A"
                      )}
                    {store?.studentState?.studentById?.studentData
                      ?.isPhoneVerified &&
                      detailFun(
                        "Is Phone Verified?:",

                        twoTagResponses(
                          store?.studentState?.studentById?.studentData
                            ?.isPhoneVerified,
                          true,
                          false
                        ) ?? "N/A"
                      )}
                  </Card>
                  {/* <Card
                    className="clientCard"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    
                  </Card> */}
                </div>
              </div>
            </>
          )}
          {current === "enquiries" && (
            <>
              <Enquiries />
            </>
          )}
          {current === "englishQualifications" && (
            <>
              <StudentAcademicQualifications
                record={store?.studentState?.studentById?.studentData}
              />
            </>
          )}
          {current === "academicQualifications" && (
            <>
              <AcademicQualifications
                record={
                  store?.studentState?.studentById?.studentData
                    ?.academicQualificationList
                }
              />
            </>
          )}
          {current === "experience" && (
            <>
              <Experience
                record={
                  store?.studentState?.studentById?.studentData
                    ?.experienceStudents
                }
              />
            </>
          )}
          {current === "documents" && (
            <>
              <StudentDocuments
                record={
                  store?.studentState?.studentById?.studentData?.documents
                }
              />
            </>
          )}
        </Card>
      </Card>
    </Drawer>
  );
};

export default StudentDetails;
