import {
  Button,
  Card,
  Divider,
  Drawer,
  Image,
  Pagination,
  Space,
  Table,
  Tag,
  Timeline,
  Tooltip,
} from "antd";
import React, { useEffect } from "react";
import {
  AiOutlineCloseCircle,
  AiOutlineEye,
  AiOutlineMail,
  AiOutlinePhone,
} from "react-icons/ai";
import client from "./../../Assets/client.png";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { BsChatLeftText, BsReply } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  formattedDateTime,
  threeTagResponses,
  threeTagResponsesTransaction,
  twoTagResponses,
} from "../../Helpers/HelperFunction";
import { allActions } from "../../Redux/myActions";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import { MdOutlineAssignmentTurnedIn, MdOutlinePending } from "react-icons/md";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import UpdateSupport from "../Support/UpdateSupport";
import ViewSupportTicket from "../Support/ViewSupportTicket";
import AssignSupportTicket from "../Support/AssignSupportTicket";
import { useNavigate } from "react-router-dom";
const columns = [
  {
    title: "Support Category",
    dataIndex: "supportCategory",
    align: "center",
  },
  {
    title: "Added Date/Time",
    dataIndex: "addedTime",
    align: "center",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    align: "center",
  },
  {
    title: "Ticket Status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const ClientDetails = ({
  record,
  isClientDetailsModelVisible,
  setIsClientDetailsModelVisible,
}) => {
  const store = useSelector((state) => {
    return {
      leadState: state.ClientReducer,
      supportState: state.SupportReducer,
    };
  });

  const [current, setCurrent] = useState("clientDetails");
  const [isUpdateSupportModalVisible, setIsUpdateSupportModalVisible] =
    useState(false);
  const [isViewSupportModalVisible, setIsViewSupportModalVisible] =
    useState(false);
  const [
    isAssignSupportTicketModalVisible,
    setIsAssignSupportTicketModalVisible,
  ] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [supportRecord, setSupportRecord] = useState({});

  const actions = useDispatch();
  const fetchClientsSupportByID = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/support/get-all-support/${record?.id}?page=${page}&pageSize=${pageSize}`,
          attempt: "FETCH_SUPPORT_TICKET_REQUEST_BY_CLIENT_ID",
          success: "FETCH_SUPPORT_TICKET_REQUEST_BY_CLIENT_ID_SUCCESS",
          failure: "FETCH_SUPPORT_TICKET_REQUEST_BY_CLIENT_ID_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  const fetchClientsAccountDetatilsByID = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/package/subscription-payment-detail/${record?.id}?page=1&pageSize=100`,
          attempt: "FETCH_ACCOUNT_DETAILS_BY_CLIENT_ID_REQUEST",
          success: "FETCH_ACCOUNT_DETAILS_BY_CLIENT_ID_REQUEST_SUCCESS",
          failure: "FETCH_ACCOUNT_DETAILS_BY_CLIENT_ID_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  const supportData = store.leadState.supportTicketsByClientID?.data?.map(
    (dataObj) => {
      let ticketStatus;
      if (dataObj.status === "OPEN") {
        ticketStatus = <p className="greenTag">OPEN</p>;
      } else if (dataObj.status === "PENDING") {
        ticketStatus = <p className="orangeTag">PENDING</p>;
      } else if (dataObj.status === "ASSIGNED") {
        ticketStatus = <p className="blueTag">ASSIGNED</p>;
      } else if (dataObj.status === "CLOSE") {
        ticketStatus = <p className="redTag">CLOSED</p>;
      }

      return {
        key: dataObj.id,
        supportCategory: dataObj?.supportCategory?.categoryName,
        addedTime: formattedDateTime(dataObj.addedTime),
        createdBy: dataObj.createdBy?.emailId,
        status: ticketStatus,

        actions: (
          <Space size="middle">
            {dataObj.status !== "CLOSE" && (
              <Tooltip title="Reply to Ticket">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateSupportModalVisible(true);
                      setSupportRecord(dataObj);
                    }}
                  >
                    <BsReply size={20} />
                  </div>
                </a>
              </Tooltip>
            )}

            <Tooltip title="View Ticket">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setSupportRecord(dataObj);
                    setIsViewSupportModalVisible(true);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {dataObj.status !== "OPEN" && (
              <Tooltip title="Mark status as open">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      actions(
                        allActions(
                          {},
                          {
                            method: "patch",
                            endPoint: `/change-ticket-status/open/${dataObj.id}`,
                            attempt: "CHANGE_SUPPORT_STATUS_REQUEST",
                            success: "CHANGE_SUPPORT_STATUS_REQUEST_SUCCESS",
                            failure: "CHANGE_SUPPORT_STATUS_REQUEST_FAILURE",
                            //   navigateTo: null,
                            successInternalState: (data) => {
                              fetchClientsSupportByID();
                            },
                            saveBearerToken: true,
                          }
                        )
                      );
                      // setSupportRecord(dataObj);
                      // setIsViewSupportModalVisible(true);
                    }}
                  >
                    <RiCheckboxBlankCircleLine />
                  </div>
                </a>
              </Tooltip>
            )}
            {dataObj.status !== "PENDING" && (
              <Tooltip title="Mark status as pending">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      actions(
                        allActions(
                          {},
                          {
                            method: "patch",
                            endPoint: `/change-ticket-status/pending/${dataObj.id}`,
                            attempt: "CHANGE_SUPPORT_STATUS_REQUEST",
                            success: "CHANGE_SUPPORT_STATUS_REQUEST_SUCCESS",
                            failure: "CHANGE_SUPPORT_STATUS_REQUEST_FAILURE",
                            //   navigateTo: null,
                            successInternalState: (data) => {
                              fetchClientsSupportByID();
                            },
                            saveBearerToken: true,
                          }
                        )
                      );
                      // setSupportRecord(dataObj);
                      // setIsViewSupportModalVisible(true);
                    }}
                  >
                    <MdOutlinePending />
                  </div>
                </a>
              </Tooltip>
            )}
            {dataObj.status !== "CLOSE" && (
              <Tooltip title="Mark status as closed">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      actions(
                        allActions(
                          {},
                          {
                            method: "patch",
                            endPoint: `/change-ticket-status/close/${dataObj.id}`,
                            attempt: "CHANGE_SUPPORT_STATUS_REQUEST",
                            success: "CHANGE_SUPPORT_STATUS_REQUEST_SUCCESS",
                            failure: "CHANGE_SUPPORT_STATUS_REQUEST_FAILURE",
                            //   navigateTo: null,
                            successInternalState: (data) => {
                              fetchClientsSupportByID();
                            },
                            saveBearerToken: true,
                          }
                        )
                      );
                      // setSupportRecord(dataObj);
                      // setIsViewSupportModalVisible(true);
                    }}
                  >
                    <AiOutlineCloseCircle />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* {dataObj.status !== "ASSIGNED" && (
              <Tooltip title="Assign support ticket">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setSupportRecord(dataObj);
                      setIsAssignSupportTicketModalVisible(true);
                    }}
                  >
                    <MdOutlineAssignmentTurnedIn />
                  </div>
                </a>
              </Tooltip>
            )} */}

            {/* <Tooltip title="View Ticket">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setSupportRecord(dataObj);
                  setIsViewSupportModalVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip> */}
          </Space>
        ),
      };
    }
  );
  const onShowSizeChange = (current, pageSize) => {
    console.log("page changed", current, pageSize);
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  useEffect(() => {
    fetchClientsSupportByID();
    fetchClientsAccountDetatilsByID();
  }, [page, pageSize]);

  const clientDetailsTabList = [
    {
      key: "clientDetails",
      tab: "Client Details",
    },
    {
      key: "accountHistory",
      tab: "Account History",
    },
    {
      key: "support",
      tab: "Support",
    },
  ];

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween">
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  const navigate = useNavigate();

  console.log("supportRecord from lead details", supportRecord);
  return (
    <Drawer
      title="Client Details"
      open={isClientDetailsModelVisible}
      onClose={() => {
        setIsClientDetailsModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.3rem" }} className="drawerStyle">
        <div
          className="flexRowWithoutStyle"
          style={{
            gap: "1rem",
            alignItems: "stretch",
          }}
        >
          {record?.companyLogo ? (
            <Image src={record?.companyLogo} className={"clientImage"} />
          ) : (
            <Image src={client} className={"clientImage"} />
          )}
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              position: "relative",
              width: "100%",
              gap: "0.5rem",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {`${record?.companyName ?? ""}`}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              {record?.phoneNumber && (
                <span className="flexRowWithGap">
                  <AiOutlinePhone
                    style={{
                      fontSize: "20",
                    }}
                  />{" "}
                  {record?.phoneNumber ? record?.phoneNumber : "N/A"}
                </span>
              )}
              {record?.companyAddress && (
                <span className="flexRowWithGap">
                  <GoLocation
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {record?.companyAddress ? record?.companyAddress : "N/A"}
                </span>
              )}
              {record?.emailId && (
                <span className="flexRowWithGap">
                  <AiOutlineMail
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {record?.emailId ? record?.emailId : "N/A"}
                </span>
              )}
            </div>
            <div>
              {/* <div>
                <span className="profileTitle">Campaign:</span>
                <span>
                  {store.leadState.leadById?.customerResponse?.campaign?.name ??
                    "N/A"}
                </span>
              </div> */}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.5rem",
                // justifySelf:""
                position: "absolute",
                alignSelf: "flex-start",
                flexWrap: "wrap",
                bottom: 0,
              }}
            >
              <Button
                type="primary"
                danger
                style={{ borderRadius: "8px" }}
                onClick={() => {
                  navigate(`/client-subscription/${record?.id}`);
                }}
              >
                Upgrade Plan
              </Button>

              {/*  <button
                  className="emailAndMessage"
                  onClick={() => {
                    setIsSendComposedEmailModalVisible(true);
                  }}
                >
                  <AiOutlineMail />
                  <span>Email</span>
                </button> */}
            </div>
            {record?.subscription?.packageName && (
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
              >
                <Tag color="#108ee9">{record?.subscription?.packageName}</Tag>
              </div>
            )}
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "clientDetails" && (
            <>
              <div
                style={{
                  padding: "0.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "row",
                  }}
                >
                  <Card
                    className="clientCard"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {detailFun(
                      "Name",
                      `${record?.firstName ?? ""} ${record?.lastName ?? ""}`
                    )}
                    {record?.userName &&
                      detailFun("User Name", `${record?.userName ?? ""}`)}
                    {record?.dob &&
                      detailFun("Date of Birth", `${record?.dob ?? ""}`)}
                    {record?.companyRegistrationNumber &&
                      detailFun(
                        "Company Registration Number",
                        `${record?.companyRegistrationNumber ?? ""}`
                      )}
                    {record?.trailStartDate &&
                      detailFun(
                        "Trail Start Date",
                        `${formattedDateTime(record?.trailStartDate) ?? ""}`
                      )}
                    {record?.trailExpiryDate &&
                      detailFun(
                        "Trail Expiry Date",
                        `${formattedDateTime(record?.trailExpiryDate) ?? ""}`
                      )}
                    {record?.abn && detailFun("ABN", `${record?.abn ?? ""}`)}
                    {record?.subscription &&
                      record?.subscription?.packageName &&
                      detailFun(
                        "Package Name",
                        `${record?.subscription?.packageName ?? ""}`
                      )}
                    {record?.subscription &&
                      record?.subscription?.packagePrice &&
                      detailFun(
                        "Package Price",
                        `${record?.subscription?.packagePrice ?? ""}`
                      )}
                    {record?.subscription &&
                      record?.subscription?.userCount &&
                      detailFun(
                        "Total User Count",
                        `${record?.subscription?.userCount ?? ""}`
                      )}
                    {record?.subscription?.packageDuration &&
                      detailFun(
                        "Package Duration",
                        `${record?.subscription?.packageDuration ?? ""}`
                      )}
                    {record?.subscription &&
                      record?.subscription?.isFree &&
                      detailFun(
                        "Is Free?",

                        twoTagResponses(
                          record?.subscription?.isFree,
                          true,
                          false
                        ) ?? ""
                      )}

                    {record?.callingCode &&
                      detailFun("Calling Code", `${record?.callingCode ?? ""}`)}
                    {record?.country &&
                      detailFun("Country", `${record?.country ?? ""}`)}

                    {/* {detailFun(
                      "Added Date",
                      `${
                        store.leadState.leadById?.addedDate
                          ? formattedDateTime(
                              store.leadState.leadById?.addedDate
                            )
                          : "N/A"
                      }`
                    )} */}
                  </Card>
                  {/* <Card
                    className="clientCard statusUpdateTimeline"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "70vh",
                    }}
                  >
                    {store.leadState?.leadActivityLogById?.data?.map(
                      (dataObj, index) => {
                        return (
                          <Timeline.Item key={dataObj.key}>
                            <div className="flexColumnwithoutStyle">
                              <div style={{ fontWeight: "200" }}>
                                {formattedDateTime(dataObj?.activityTime)}
                              </div>
                              <span>
                                <div style={{ fontWeight: "bold" }}>
                                  {dataObj.activity}
                                </div>
                              </span>
                            </div>
                          </Timeline.Item>
                        );
                      }
                    )}
                  </Card> */}
                </div>
              </div>
            </>
          )}
          {current === "accountHistory" && (
            <>
              <Card
                className="clientCard statusUpdateTimeline"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "70vh",
                }}
              >
                {store.leadState?.accountDetailsByClientId?.data?.map(
                  (dataObj, index) => {
                    return (
                      <Timeline.Item key={dataObj.key}>
                        <div className="flexColumnwithoutStyle">
                          <div style={{ fontWeight: "200" }}>
                            {formattedDateTime(
                              dataObj?.transactionInitiatedTime
                            )}
                          </div>
                          <span>
                            <div style={{ fontWeight: "bold" }}>
                              Payment Method: {dataObj.paymentMethod}
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Payment Amount: {dataObj.paymentAmount}
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Total User Allowed: {dataObj.totalUserCount}
                            </div>
                            <div>
                              Transaction Status:
                              {threeTagResponsesTransaction(
                                dataObj.transactionStatus,
                                "INITIATED",
                                "FAILED",
                                "COMPLETED"
                              )}
                            </div>
                            <div>
                              Subscription Type: {dataObj.subscriptionType}
                            </div>
                            {dataObj.receiptNo && (
                              <div>Receipt No.: {dataObj.receiptNo}</div>
                            )}
                            {dataObj?.transactionInitiatedTime && (
                              <div style={{ fontWeight: "200" }}>
                                Transaction Initiated At:{" "}
                                {formattedDateTime(
                                  dataObj?.transactionInitiatedTime
                                )}
                              </div>
                            )}
                            {dataObj?.transactionFailedTime && (
                              <div style={{ fontWeight: "200" }}>
                                Transaction Failed At:{" "}
                                {formattedDateTime(
                                  dataObj?.transactionFailedTime
                                )}
                              </div>
                            )}
                            {dataObj?.transactionCompletedTime && (
                              <div style={{ fontWeight: "200" }}>
                                Transaction Completed At:{" "}
                                {formattedDateTime(
                                  dataObj?.transactionCompletedTime
                                )}
                              </div>
                            )}
                          </span>
                        </div>
                      </Timeline.Item>
                    );
                  }
                )}
              </Card>
            </>
          )}
          {current === "support" && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "2rem",
                }}
              >
                {store.supportState.isLoading ? (
                  <TableSkeleton />
                ) : (
                  <Table
                    bordered
                    columns={columns}
                    dataSource={supportData}
                    pagination={false}
                  />
                )}

                {store.leadState.supportTicketsByClientID?.totalData ? (
                  <Pagination
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      alignSelf: "flex-end",
                    }}
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    defaultCurrent={1}
                    current={page}
                    defaultPageSize={pageSize}
                    onChange={onShowSizeChange}
                    total={
                      store.leadState.supportTicketsByClientID?.totalPage * 10
                    }
                  />
                ) : null}
              </div>
            </>
          )}
        </Card>
      </Card>
      {isUpdateSupportModalVisible && (
        <UpdateSupport
          record={supportRecord}
          isUpdateSupportModalVisible={isUpdateSupportModalVisible}
          setIsUpdateSupportModalVisible={setIsUpdateSupportModalVisible}
        />
      )}
      {isViewSupportModalVisible && (
        <ViewSupportTicket
          supportType={supportRecord?.status}
          page={page}
          pageSize={pageSize}
          record={supportRecord}
          isViewSupportTicketModalVisible={isViewSupportModalVisible}
          setIsViewSupportTicketModalVisible={setIsViewSupportModalVisible}
          fetchClientsSupportByID={fetchClientsSupportByID}
        />
      )}
      {isAssignSupportTicketModalVisible && (
        <AssignSupportTicket
          record={supportRecord}
          isAssignSupportTicketModalVisible={isAssignSupportTicketModalVisible}
          setIsAssignSupportTicketModalVisible={
            setIsAssignSupportTicketModalVisible
          }
          fetchClientsSupportByID={fetchClientsSupportByID}
        />
      )}
    </Drawer>
  );
};

export default ClientDetails;
