import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../components/Siderdemo";
import { allActions } from "../../Redux/myActions";
import { BiBlock } from "react-icons/bi";
import { AiOutlinePoweroff } from "react-icons/ai";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import AddSubscription from "./AddSubscription";
import UpdateSubscription from "./UpdateSubscription";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Package Name",
    dataIndex: "packageName",
    align: "center",
  },
  {
    title: "Package Price",
    dataIndex: "packagePrice",
    align: "center",
  },
  {
    title: "Package Duration",
    dataIndex: "packageDuration",
    align: "center",
  },
  {
    title: "Discount",
    dataIndex: "discount",
    align: "center",
  },
  {
    title: "User Count",
    dataIndex: "userCount",
    align: "center",
  },
  {
    title: "Price Per User",
    dataIndex: "pricePerUser",
    align: "center",
  },
  {
    title: "Subscription Duration",
    dataIndex: "subscriptionDuration",
    align: "center",
  },
  {
    title: "Is Free",
    dataIndex: "isFree",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const Subscription = () => {
  const SubscriptionState = useSelector((state) => state.SubscriptionReducer);
  const [isAddSubscriptionModalVisible, setIsAddSubscriptionModalVisible] =
    useState(false);
  const [
    isUpdateSubscriptionModalVisible,
    setIsUpdateSubscriptionModalVisible,
  ] = useState(false);
  const [record, setRecord] = useState({});
  const [SubscriptionType, setSubscriptionType] = useState("ALL");

  const actions = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  const fetchAllSubscriptions = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/package/get-all?page=${page}&pageSize=${pageSize}`,
          attempt: "FETCH_SUBSCRIPTIONS_REQUEST",
          success: "FETCH_SUBSCRIPTIONS_REQUEST_SUCCESS",
          failure: "FETCH_SUBSCRIPTIONS_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  const fetchActiveSubscriptions = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/package/get-all-active?page=${page}&pageSize=${pageSize}`,
          attempt: "FETCH_ACTIVE_SUBSCRIPTIONS_REQUEST",
          success: "FETCH_ACTIVE_SUBSCRIPTIONS_REQUEST_SUCCESS",
          failure: "FETCH_ACTIVE_SUBSCRIPTIONS_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  useEffect(() => {
    if (SubscriptionType === "ACTIVE") {
      fetchActiveSubscriptions();
    } else {
      fetchAllSubscriptions();
    }
  }, [SubscriptionType, page, pageSize]);

  let data = [];
  if (SubscriptionType === "ALL") {
    data = SubscriptionState.subscriptions?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: dataObj.id,
        packageName: dataObj.packageName,
        packagePrice: dataObj.packagePrice,
        packageDuration: dataObj.packageDuration,
        discount: dataObj.discount,
        userCount: dataObj.userCount,
        pricePerUser: dataObj.pricePerUser,
        subscriptionDuration: dataObj.subscriptionDuration,
        discount: dataObj.discount,

        isFree: dataObj.isFree ? (
          <p className="greenTag">Yes</p>
        ) : (
          <p className="redTag">No</p>
        ),
        status: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),

        actions: (
          <Space size="middle">
            {dataObj.isActive && (
              <Tooltip title="Update Subscription">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsUpdateSubscriptionModalVisible(true);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* <Tooltip title="Delete Subscription">
              <Popconfirm
                title="Are you sure to delete this Subscription?"
                onConfirm={() => {
                  actions(
                    allActions(
                      {},
                      {
                        method: "delete",
                        endPoint: `/Subscription/delete/${dataObj.id}`,
                        attempt: "DELETE_Subscription_REQUEST",
                        success: "DELETE_Subscription_REQUEST_SUCCESS",
                        failure: "DELETE_Subscription_REQUEST_FAILURE",
                        //   navigateTo: null,
                        successInternalState: (data) => {
                          fetchActiveSubscriptions();
                          fetchAllSubscriptions();
                        },
                        saveBearerToken: true,
                      }
                    )
                  );
                  //   dispatch({
                  //     type: "DELETE_Subscription_REQUEST",
                  //     payload: { id: dataObj.id },
                  //   });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip> */}
            {dataObj.isActive ? (
              <Tooltip title="Deactivate Subscription">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = dataObj.isActive
                        ? "inactive"
                        : "active";
                      actions(
                        allActions(
                          {},
                          {
                            method: "post",
                            endPoint: `/package/add/deactivate-package/${dataObj.id}`,
                            attempt: "TOGGLE_SUBSCRIPTION_STATUS_REQUEST",
                            success:
                              "TOGGLE_SUBSCRIPTION_STATUS_REQUEST_SUCCESS",
                            failure:
                              "TOGGLE_SUBSCRIPTION_STATUS_REQUEST_FAILURE",
                            //   navigateTo: null,
                            successInternalState: (data) => {
                              fetchActiveSubscriptions();
                              fetchAllSubscriptions();
                            },
                            saveBearerToken: true,
                          }
                        )
                      );
                      // dispatch({
                      //   type: "TOGGLE_Subscription_STATUS_REQUEST",
                      //   payload: { id: dataObj.id, actionStatus },
                      // });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            ) : null}
            {!dataObj.isActive ? (
              <Tooltip title="Activate Subscription">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = dataObj.isActive
                        ? "inactive"
                        : "active";
                      actions(
                        allActions(
                          {},
                          {
                            method: "post",
                            endPoint: `/package/add/activate-package/${dataObj.id}`,
                            attempt: "TOGGLE_SUBSCRIPTION_STATUS_REQUEST",
                            success:
                              "TOGGLE_SUBSCRIPTION_STATUS_REQUEST_SUCCESS",
                            failure:
                              "TOGGLE_SUBSCRIPTION_STATUS_REQUEST_FAILURE",
                            //   navigateTo: null,
                            successInternalState: (data) => {
                              fetchActiveSubscriptions();
                              fetchAllSubscriptions();
                            },
                            saveBearerToken: true,
                          }
                        )
                      );
                      // dispatch({
                      //   type: "TOGGLE_Subscription_STATUS_REQUEST",
                      //   payload: { id: dataObj.id, actionStatus },
                      // });
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            ) : null}
          </Space>
        ),
      };
    });
  } else {
    data = SubscriptionState.activeSubscriptions?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: dataObj.id,
        packageName: dataObj.packageName,
        packagePrice: dataObj.packagePrice,
        packageDuration: dataObj.packageDuration,
        discount: dataObj.discount,
        userCount: dataObj.userCount,
        pricePerUser: dataObj.pricePerUser,
        subscriptionDuration: dataObj.subscriptionDuration,
        discount: dataObj.discount,

        isFree: dataObj.isFree ? (
          <p className="greenTag">Yes</p>
        ) : (
          <p className="redTag">No</p>
        ),
        status: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {dataObj.isActive && (
              <Tooltip title="Update Subscription">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsUpdateSubscriptionModalVisible(true);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            {/* <Tooltip title="Delete Subscription">
              <Popconfirm
                title="Are you sure to delete this Subscription?"
                onConfirm={() => {
                  actions(
                    allActions(
                      {},
                      {
                        method: "delete",
                        endPoint: `/Subscription/delete/${dataObj.id}`,
                        attempt: "DELETE_Subscription_REQUEST",
                        success: "DELETE_Subscription_REQUEST_SUCCESS",
                        failure: "DELETE_Subscription_REQUEST_FAILURE",
                        //   navigateTo: null,
                        successInternalState: (data) => {
                          fetchActiveSubscriptions();
                          fetchAllSubscriptions();
                        },
                        saveBearerToken: true,
                      }
                    )
                  );
                  //   dispatch({
                  //     type: "DELETE_Subscription_REQUEST",
                  //     payload: { id: dataObj.id },
                  //   });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip> */}
            {dataObj.isActive ? (
              <Tooltip title="Deactivate Subscription">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = dataObj.isActive
                        ? "inactive"
                        : "active";
                      actions(
                        allActions(
                          {},
                          {
                            method: "post",
                            endPoint: `/package/add/deactivate-package/${dataObj.id}`,
                            attempt: "TOGGLE_SUBSCRIPTION_STATUS_REQUEST",
                            success:
                              "TOGGLE_SUBSCRIPTION_STATUS_REQUEST_SUCCESS",
                            failure:
                              "TOGGLE_SUBSCRIPTION_STATUS_REQUEST_FAILURE",
                            //   navigateTo: null,
                            successInternalState: (data) => {
                              fetchActiveSubscriptions();
                              fetchAllSubscriptions();
                            },
                            saveBearerToken: true,
                          }
                        )
                      );
                      // dispatch({
                      //   type: "TOGGLE_Subscription_STATUS_REQUEST",
                      //   payload: { id: dataObj.id, actionStatus },
                      // });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            ) : null}
          </Space>
        ),
      };
    });
  }
  console.log("from Subscription state", SubscriptionState);
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          <button
            className="button"
            onClick={() => {
              setIsAddSubscriptionModalVisible(true);
            }}
          >
            <span>Add Subscription</span>
          </button>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>All Subscription</div>
                  </div>
                ),
                value: "ALL",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Active Subscription</div>
                  </div>
                ),
                value: "ACTIVE",
              },
            ]}
            onChange={(value) => {
              setSubscriptionType(value);
              console.log(value);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "4rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {SubscriptionState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          )}

          {SubscriptionType === "ALL" &&
          SubscriptionState.subscriptions?.totalData ? (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={SubscriptionState.subscriptions?.totalPage * 10}
            />
          ) : null}
          {SubscriptionType === "ACTIVE" &&
          SubscriptionState.activeSubscriptions?.totalData ? (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={SubscriptionState.activeSubscriptions?.totalPage * 10}
            />
          ) : null}
        </div>
      </div>
      {isAddSubscriptionModalVisible && (
        <AddSubscription
          isAddSubscriptionModalVisible={isAddSubscriptionModalVisible}
          setIsAddSubscriptionModalVisible={setIsAddSubscriptionModalVisible}
          fetchActiveSubscriptions={fetchActiveSubscriptions}
          fetchAllSubscriptions={fetchAllSubscriptions}
        />
      )}
      {isUpdateSubscriptionModalVisible && (
        <UpdateSubscription
          record={record}
          isUpdateSubscriptionModalVisible={isUpdateSubscriptionModalVisible}
          setIsUpdateSubscriptionModalVisible={
            setIsUpdateSubscriptionModalVisible
          }
          fetchActiveSubscriptions={fetchActiveSubscriptions}
          fetchAllSubscriptions={fetchAllSubscriptions}
        />
      )}
    </SiderDemo>
  );
};

export default Subscription;
