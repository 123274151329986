import React, { useEffect, useState } from "react";
import SiderDemo from "../../components/Siderdemo";
import ClientLayout from "./ClientLayout";

const ClientManagement = () => {
  return (
    <SiderDemo>
      <ClientLayout />
    </SiderDemo>
  );
};

export default ClientManagement;
