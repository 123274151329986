import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import AddAnnouncement from "./AddAnnouncement";
import UpdateAnnouncement from "./UpdateAnnouncement";
import SiderDemo from "../../components/Siderdemo";
import { allActions } from "../../Redux/myActions";
import { BiBlock } from "react-icons/bi";
import { AiOutlinePoweroff } from "react-icons/ai";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
const columns = [
  {
    title: "Announcement ID",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Announcement",
    dataIndex: "announcementText",
    align: "center",
  },
  {
    title: "Announcement Type",
    dataIndex: "announcementType",
    align: "center",
  },
  {
    title: "Announcement Start Date",
    dataIndex: "announcementStartDate",
    align: "center",
  },
  {
    title: "Announcement End Date",
    dataIndex: "announcementEndDate",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const Announcement = () => {
  const announcementState = useSelector((state) => state.AnnouncementReducer);
  const [isAddAnnouncementModalVisible, setIsAddAnnouncementModalVisible] =
    useState(false);
  const [
    isUpdateAnnouncementModalVisible,
    setIsUpdateAnnouncementModalVisible,
  ] = useState(false);
  const [record, setRecord] = useState({});
  const [announcementType, setAnnouncementType] = useState("announcements");

  const actions = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  const fetchAllAnnouncements = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/announcement/get-all?page=${page}&pageSize=${pageSize}`,
          attempt: "FETCH_ANNOUNCEMENT_REQUEST",
          success: "FETCH_ANNOUNCEMENT_REQUEST_SUCCESS",
          failure: "FETCH_ANNOUNCEMENT_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  const fetchActiveAnnouncements = () => {
    actions(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/announcement/get-all-active?page=${page}&pageSize=${pageSize}`,
          attempt: "FETCH_ACTIVE_ANNOUNCEMENT_REQUEST",
          success: "FETCH_ACTIVE_ANNOUNCEMENT_REQUEST_SUCCESS",
          failure: "FETCH_ACTIVE_ANNOUNCEMENT_REQUEST_FAILURE",
          //   navigateTo: null,
          //   successInternalState: (data) => {
          //     navigation.navigate("LeadDetails", { item });
          //   },
          saveBearerToken: true,
        }
      )
    );
  };
  useEffect(() => {
    if (announcementType === "activeAnnouncements") {
      fetchActiveAnnouncements();
    } else {
      fetchAllAnnouncements();
    }
  }, [announcementType, page, pageSize]);

  let data = [];
  if (announcementType === "announcements") {
    data = announcementState.announcements?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: dataObj.id,
        announcementText: dataObj.announcementText,
        announcementType: dataObj.announcementType,
        announcementStartDate: dataObj.announcementStartDate,
        announcementEndDate: dataObj.announcementEndDate,
        status: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),

        actions: (
          <Space size="middle">
            {dataObj.isActive && (
              <Tooltip title="Update announcement">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateAnnouncementModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            <Tooltip title="Delete announcement">
              <Popconfirm
                title="Are you sure to delete this announcement?"
                onConfirm={() => {
                  actions(
                    allActions(
                      {},
                      {
                        method: "delete",
                        endPoint: `/announcement/delete/${dataObj.id}`,
                        attempt: "DELETE_ANNOUNCEMENT_REQUEST",
                        success: "DELETE_ANNOUNCEMENT_REQUEST_SUCCESS",
                        failure: "DELETE_ANNOUNCEMENT_REQUEST_FAILURE",
                        //   navigateTo: null,
                        successInternalState: (data) => {
                          fetchActiveAnnouncements();
                          fetchAllAnnouncements();
                        },
                        saveBearerToken: true,
                      }
                    )
                  );
                  //   dispatch({
                  //     type: "DELETE_ANNOUNCEMENT_REQUEST",
                  //     payload: { id: dataObj.id },
                  //   });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip>
            {dataObj.isActive ? (
              <Tooltip title="Deactivate announcement">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = dataObj.isActive
                        ? "inactive"
                        : "active";
                      actions(
                        allActions(
                          {},
                          {
                            method: "patch",
                            endPoint: `/announcement/change-status/${actionStatus}/${dataObj.id}`,
                            attempt: "TOGGLE_ANNOUNCEMENT_STATUS_REQUEST",
                            success:
                              "TOGGLE_ANNOUNCEMENT_STATUS_REQUEST_SUCCESS",
                            failure:
                              "TOGGLE_ANNOUNCEMENT_STATUS_REQUEST_FAILURE",
                            //   navigateTo: null,
                            successInternalState: (data) => {
                              fetchActiveAnnouncements();
                              fetchAllAnnouncements();
                            },
                            saveBearerToken: true,
                          }
                        )
                      );
                      // dispatch({
                      //   type: "TOGGLE_ANNOUNCEMENT_STATUS_REQUEST",
                      //   payload: { id: dataObj.id, actionStatus },
                      // });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            ) : null}
            {!dataObj.isActive ? (
              <Tooltip title="Activate announcement">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = dataObj.isActive
                        ? "inactive"
                        : "active";
                      actions(
                        allActions(
                          {},
                          {
                            method: "patch",
                            endPoint: `/announcement/change-status/${actionStatus}/${dataObj.id}`,
                            attempt: "TOGGLE_ANNOUNCEMENT_STATUS_REQUEST",
                            success:
                              "TOGGLE_ANNOUNCEMENT_STATUS_REQUEST_SUCCESS",
                            failure:
                              "TOGGLE_ANNOUNCEMENT_STATUS_REQUEST_FAILURE",
                            //   navigateTo: null,
                            successInternalState: (data) => {
                              fetchActiveAnnouncements();
                              fetchAllAnnouncements();
                            },
                            saveBearerToken: true,
                          }
                        )
                      );
                      // dispatch({
                      //   type: "TOGGLE_ANNOUNCEMENT_STATUS_REQUEST",
                      //   payload: { id: dataObj.id, actionStatus },
                      // });
                    }}
                  >
                    <AiOutlinePoweroff />
                  </div>
                </a>
              </Tooltip>
            ) : null}
          </Space>
        ),
      };
    });
  } else {
    data = announcementState.activeAnnouncements?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: dataObj.id,
        announcementText: dataObj.announcementText,
        announcementType: dataObj.announcementType,
        announcementStartDate: dataObj.announcementStartDate,
        announcementEndDate: dataObj.announcementEndDate,

        status: dataObj.isActive ? (
          <p className="greenTag">ACTIVE</p>
        ) : (
          <p className="redTag">INACTIVE</p>
        ),
        actions: (
          <Space size="middle">
            {dataObj.isActive && (
              <Tooltip title="Update announcement">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateAnnouncementModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            <Tooltip title="Delete announcement">
              <Popconfirm
                title="Are you sure to delete this announcement?"
                onConfirm={() => {
                  actions(
                    allActions(
                      {},
                      {
                        method: "delete",
                        endPoint: `/announcement/delete/${dataObj.id}`,
                        attempt: "DELETE_ANNOUNCEMENT_REQUEST",
                        success: "DELETE_ANNOUNCEMENT_REQUEST_SUCCESS",
                        failure: "DELETE_ANNOUNCEMENT_REQUEST_FAILURE",
                        //   navigateTo: null,
                        successInternalState: (data) => {
                          fetchActiveAnnouncements();
                          fetchAllAnnouncements();
                        },
                        saveBearerToken: true,
                      }
                    )
                  );
                  //   dispatch({
                  //     type: "DELETE_ANNOUNCEMENT_REQUEST",
                  //     payload: { id: dataObj.id },
                  //   });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip>
            {dataObj.isActive ? (
              <Tooltip title="Deactivate announcement">
                <a style={{ color: "red" }}>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus = dataObj.isActive
                        ? "inactive"
                        : "active";
                      actions(
                        allActions(
                          {},
                          {
                            method: "patch",
                            endPoint: `/announcement/change-status/${actionStatus}/${dataObj.id}`,
                            attempt: "TOGGLE_ANNOUNCEMENT_STATUS_REQUEST",
                            success:
                              "TOGGLE_ANNOUNCEMENT_STATUS_REQUEST_SUCCESS",
                            failure:
                              "TOGGLE_ANNOUNCEMENT_STATUS_REQUEST_FAILURE",
                            //   navigateTo: null,
                            successInternalState: (data) => {
                              fetchActiveAnnouncements();
                              fetchAllAnnouncements();
                            },
                            saveBearerToken: true,
                          }
                        )
                      );
                      // dispatch({
                      //   type: "TOGGLE_ANNOUNCEMENT_STATUS_REQUEST",
                      //   payload: { id: dataObj.id, actionStatus },
                      // });
                    }}
                  >
                    <BiBlock />
                  </div>
                </a>
              </Tooltip>
            ) : null}
          </Space>
        ),
      };
    });
  }
  console.log("from announcement state", announcementState);
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="flexRow">
          <button
            className="button"
            onClick={() => {
              setIsAddAnnouncementModalVisible(true);
            }}
          >
            <span>Add Announcement</span>
          </button>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>All Announcement</div>
                  </div>
                ),
                value: "announcements",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Active Announcement</div>
                  </div>
                ),
                value: "activeAnnouncements",
              },
            ]}
            onChange={(value) => {
              setAnnouncementType(value);
              console.log(value);
            }}
          />
        </div>
        <div
          style={{
            marginTop: "4rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {announcementState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          )}

          {announcementState.announcements?.totalData ? (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={announcementState.announcements?.totalPage * 10}
            />
          ) : null}
        </div>
      </div>
      {isAddAnnouncementModalVisible && (
        <AddAnnouncement
          isAddAnnouncementModalVisible={isAddAnnouncementModalVisible}
          setIsAddAnnouncementModalVisible={setIsAddAnnouncementModalVisible}
          fetchActiveAnnouncements={fetchActiveAnnouncements}
          fetchAllAnnouncements={fetchAllAnnouncements}
        />
      )}
      {isUpdateAnnouncementModalVisible && (
        <UpdateAnnouncement
          record={record}
          isUpdateAnnouncementModalVisible={isUpdateAnnouncementModalVisible}
          setIsUpdateAnnouncementModalVisible={
            setIsUpdateAnnouncementModalVisible
          }
          fetchActiveAnnouncements={fetchActiveAnnouncements}
          fetchAllAnnouncements={fetchAllAnnouncements}
        />
      )}
    </SiderDemo>
  );
};

export default Announcement;
