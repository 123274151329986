import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Upload,
  message,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import { UploadOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import { allActions } from "../../Redux/myActions";
import { useSelector } from "react-redux";
const { Option } = Select;

const AddEditMigarionVisaSubclass = ({
  modalType,
  record,
  isMigrationVisasModalVisible,
  setIsMigrationVisasModalVisible,
  fetchSubclassByCategory,
}) => {
  const store = useSelector((state) => {
    return {
      caseState: state.InstituteReducer,
    };
  });
  const actions = useDispatch();
  const [form] = Form.useForm();
  const [selectedDocument, setSelectedDocument] = useState(null);

  const [fileList, setFileList] = useState([]);

  const addEducationVisaSubmitHandler = (values) => {
    const formData = new FormData();
    // selectedDocument && formData.append("document", selectedDocument);
    formData.append("subClass", values?.subClass);
    formData.append("country", values?.country);
    formData.append("description", values?.description);
    formData.append("visaCategory", "MIGRATION");

    actions(
      allActions(formData, {
        method: "post",
        endPoint:
          modalType === "Add"
            ? "/visa-sub-class/add"
            : `/visa-sub-class/update/${record?.id}`,
        attempt: "ADD_VISA_SUBCLASS_REQUEST",
        success: "ADD_VISA_SUBCLASS_REQUEST_SUCCESS",
        failure: "ADD_VISA_SUBCLASS_REQUEST_FAILURE",
        multipartFormData: true,
        saveBearerToken: false,
        successInternalState: () => {
          setIsMigrationVisasModalVisible(false);
          fetchSubclassByCategory();
        },
      })
    );
  };
  return (
    <Modal
      title={`${modalType} Migration Visa`}
      open={isMigrationVisasModalVisible}
      onCancel={() => {
        setIsMigrationVisasModalVisible(false);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={addEducationVisaSubmitHandler}
        colon={true}
        form={form}
        initialValues={{
          country: record?.country,
          subClass: record?.subClass,
          description: record?.description,
        }}
      >
        <Form.Item
          label="Country"
          name={"country"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please select country name!",
            },
          ]}
        >
          <Select placeholder="Country name">
            {store?.caseState?.adminDropdown?.country?.map((country) => (
              <Select.Option value={country.countryName}>
                {country.countryName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Visa class"
          name={"subClass"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter visa class name!",
            },
          ]}
        >
          <Input placeholder="Visa class name" />
        </Form.Item>

        <Form.Item
          label="Description"
          name={"description"}
          style={{ width: "100%", marginBottom: "3rem" }}
        >
          <ReactQuill
            style={{
              background: "white",
              height: "200px",
              marginBottom: "2.5rem",
            }}
            modules={{
              toolbar: {
                container: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ align: [] }],
                  ["link", "image"],
                  ["clean"],
                  [{ color: [] }],
                ],
              },
            }}
            theme="snow"
          />
        </Form.Item>
        {/* <Form.Item
          label="Add New Attachment"
          name="document"
          style={{ paddingTop: "1rem" }}
        >
          <Input
            onChange={(e) => {
              setSelectedDocument(e.target.files[0]);
            }}
            placeholder="Upload New Attachment"
            type="file"
          />
        </Form.Item> */}

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            {modalType} Migration Visa
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditMigarionVisaSubclass;
